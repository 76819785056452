export default function Logo({
  color = "black",
  width = "300",
  className = "",
}) {
  return (
    <>
      <svg
        viewBox="0 0 320 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g clipPath="url(#clip0_1_1139)">
          <path
            d="M313.864 29.9672C311.778 28.6943 308.955 28.0646 306.734 27.6584C305.488 27.4214 301.995 26.6428 300.81 25.8912C299.016 24.7537 298.142 22.8715 298.406 20.7252C298.711 18.2606 300.566 16.4461 302.997 15.769C307.567 14.4961 311.122 16.9945 313.789 18.8023L314.202 19.0799C314.405 19.2153 314.649 19.2559 314.886 19.2017C315.123 19.1408 315.32 18.9919 315.435 18.7752L318.102 13.8529C318.278 13.4737 318.258 13.1284 318.102 12.9253C317.899 12.6612 317.554 12.6071 317.175 12.5394C314.94 12.1534 312.882 11.2055 310.77 10.4201C310.228 10.217 309.686 10.0274 309.131 9.86492C305.597 8.84255 302.503 9.47899 300.275 10.3592C294.391 12.6815 292.712 17.2856 292.231 19.8314C291.331 24.5912 293.321 28.5589 297.303 31.0099C299.341 32.2692 302.252 32.8312 304.507 33.3526C306.579 33.8265 308.928 34.4494 310.153 35.1468C311.88 36.1286 313.39 38.3155 312.78 41.2878C311.995 45.1336 308.732 45.9664 307.371 46.1492C305.38 46.4133 302.78 45.8987 300.742 44.8492C298.176 43.5289 296.788 42.4389 295.617 41.0983C295.305 40.7394 294.723 40.8071 294.405 41.1524L291.175 45.8107C290.911 46.1018 290.877 46.5284 291.087 46.8601C291.371 47.2935 292.001 47.456 292.468 47.6117C295.055 48.4648 297.289 50.1101 299.781 51.1731C302.042 52.1345 304.249 52.5476 306.335 52.5476C309.077 52.5476 311.643 51.7621 314.013 50.1846C316.592 48.4716 318.231 45.8107 318.874 42.2899C319.761 37.4556 317.879 32.4114 313.85 29.9604L313.864 29.9672Z"
            fill="white"
          />
          <path
            d="M241.417 9.67548H236.197C235.878 9.67548 235.621 9.93277 235.621 10.251V34.2938C232.926 29.9673 230.516 27.32 227.286 23.9143C223.061 19.4659 219.771 16.5748 215.18 13.4806C213.413 12.289 209.574 10.4744 207.753 9.70934C207.374 9.54684 206.954 9.83121 206.954 10.2375V16.3379C206.954 16.5884 207.116 16.8118 207.36 16.8863C209.256 17.4686 212.56 19.73 216.812 23.0206C221.03 26.2773 225.37 31.0236 228.295 35.0386C230.78 38.4511 233.705 43.2854 234.978 45.4181C235.398 46.1223 235.614 46.928 235.614 47.7473V51.4779C235.614 51.7961 235.872 52.0534 236.19 52.0534H241.41C241.728 52.0534 241.986 51.7961 241.986 51.4779V10.251C241.986 9.93277 241.728 9.67548 241.41 9.67548H241.417Z"
            fill="white"
          />
          <path
            d="M126.416 24.2526C125.184 23.3318 124.602 23.0407 123.945 22.8172C123.579 22.6954 123.322 22.7021 123.234 22.7089C122.882 22.7292 122.618 23.0339 122.618 23.386V45.0929C122.618 46.1085 122.489 47.1038 122.232 48.0382C121.771 49.7106 121.108 50.2793 121.108 50.997C121.108 51.6402 121.609 52.0532 122.55 52.0532H128.007C128.583 52.0532 128.894 51.735 128.894 50.6517V27.9833C128.894 27.2318 128.894 26.7917 128.515 26.2432C128.326 25.9724 127.594 25.1396 126.403 24.2459L126.416 24.2526Z"
            fill="white"
          />
          <path
            d="M210.752 24.2526C209.52 23.3318 208.944 23.0407 208.281 22.8172C207.915 22.6954 207.658 22.7021 207.57 22.7089C207.218 22.7292 206.954 23.0339 206.954 23.386V45.0929C206.954 46.1085 206.825 47.1038 206.568 48.0382C206.107 49.7106 205.444 50.2793 205.444 50.997C205.444 51.6402 205.945 52.0532 206.886 52.0532H212.343C212.919 52.0532 213.23 51.735 213.23 50.6517V27.9833C213.23 27.2318 213.23 26.7917 212.851 26.2432C212.661 25.9724 211.93 25.1396 210.739 24.2459L210.752 24.2526Z"
            fill="white"
          />
          <path
            d="M170.622 45.0998V23.3929C170.622 23.0408 170.358 22.7361 170.006 22.7158C169.986 22.7158 169.952 22.7158 169.911 22.7158C169.789 22.7158 169.573 22.7361 169.295 22.8309C168.632 23.0544 168.056 23.3455 166.824 24.2663C165.632 25.1533 164.901 25.9929 164.711 26.2637C164.339 26.8121 164.332 27.2522 164.332 28.0038V50.6721C164.332 51.7487 164.644 52.0737 165.219 52.0737H170.676C171.618 52.0737 172.119 51.6607 172.119 51.0175C172.119 50.2998 171.448 49.731 170.995 48.0586C170.737 47.1243 170.609 46.129 170.609 45.1134L170.622 45.0998Z"
            fill="white"
          />
          <path
            d="M45.5739 45.7768H44.8765H42.1682H29.446V10.2508C29.446 9.93258 29.1887 9.67529 28.8705 9.67529H23.6977C23.3794 9.67529 23.1221 9.93258 23.1221 10.2508V42.8451C23.0409 45.5601 22.8919 47.2325 22.5331 48.5121C22.2216 49.6361 21.7612 50.2522 21.7612 51.0038C21.7612 51.5928 22.181 51.9788 22.9664 52.0465C23.007 52.0532 23.0477 52.06 23.0883 52.06H25.7898H39.1484H42.1546H42.3578C43.0484 52.06 43.6171 52.0871 44.064 51.8298C44.7072 51.4642 45.9056 47.7606 46.1291 47.1038C46.2509 46.7382 46.2442 46.4809 46.2374 46.3929C46.2171 46.0408 45.9124 45.7768 45.5603 45.7768H45.5739Z"
            fill="white"
          />
          <path
            d="M192.37 38.3426V16.6356C192.37 15.62 192.498 14.6315 192.756 13.6903C193.216 12.018 193.88 11.4492 193.88 10.7315C193.88 10.0883 193.379 9.67529 192.437 9.67529H189.228H186.019C185.078 9.67529 184.577 10.0883 184.577 10.7315C184.577 11.4492 185.247 12.018 185.701 13.6903C185.958 14.6247 186.087 15.62 186.087 16.6356C186.087 17.2991 186.087 20.1022 186.087 23.3928V45.1065C186.087 46.1221 185.958 47.1174 185.701 48.0518C185.24 49.7241 184.577 50.2929 184.577 51.0106C184.577 51.6538 185.078 52.0668 186.019 52.0668H192.437C193.379 52.0668 193.88 51.6538 193.88 51.0106C193.88 50.2929 193.209 49.7241 192.756 48.0518C192.498 47.1174 192.37 46.1221 192.37 45.1065V38.3493V38.3426Z"
            fill="white"
          />
          <path
            d="M7.7931 38.3425V36.2504C7.7931 36.2504 7.7931 18.4501 7.7931 16.6356C7.7931 15.62 7.92175 14.6315 8.17226 13.6903C8.63267 12.018 9.2962 11.4492 9.2962 10.7315C9.2962 10.0883 8.79517 9.67529 7.85404 9.67529H1.44216C0.501034 9.67529 0 10.0883 0 10.7315C0 11.4492 0.670302 12.018 1.12394 13.6903C1.38123 14.6247 1.50987 15.62 1.50987 16.6356V23.3928V45.0997C1.50987 46.1153 1.38123 47.1106 1.12394 48.045C0.663531 49.7173 0 50.2861 0 51.0038C0 51.647 0.501034 52.06 1.44216 52.06H7.86081C8.80194 52.06 9.30298 51.647 9.30298 51.0038C9.30298 50.2861 8.63267 49.7173 8.17904 48.045C7.92175 47.1106 7.79988 46.1153 7.79988 45.0997V38.3425H7.7931Z"
            fill="white"
          />
          <path
            d="M75.568 46.007C75.1482 45.7023 74.4508 45.7768 73.7602 45.7768H73.5571H60.8823V10.2508C60.8823 9.93258 60.625 9.67529 60.3068 9.67529H55.1339C54.8157 9.67529 54.5584 9.93258 54.5584 10.2508V42.8451C54.4772 45.5601 54.3282 47.2325 53.9694 48.5122C53.6579 49.6361 53.1975 50.2522 53.1975 51.0038C53.1975 51.5928 53.6173 51.9788 54.4027 52.0465C54.4433 52.0532 54.4839 52.06 54.5246 52.06H57.1855H73.5503H76.2586H77.8091C78.1612 52.06 78.4862 51.796 78.4862 51.4439C78.4862 50.6178 76.069 46.3794 75.5544 46.007H75.568Z"
            fill="white"
          />
          <path
            d="M109.706 9.66187H104.283C103.707 9.66187 103.396 9.98009 103.396 11.0634V33.4271V37.8349C103.396 42.9806 100.39 46.4404 95.9209 46.4404C91.4522 46.4404 88.446 42.9806 88.446 37.8349V33.4271V16.8321V11.0634C88.446 9.98686 88.1346 9.66187 87.5591 9.66187H82.1357C81.7363 9.66187 81.4316 10.0139 81.4857 10.4066C81.5467 10.8332 81.8581 11.6389 81.9732 12.6681C82.217 14.8212 82.1628 15.9654 82.1628 16.8321V38.2614C82.1831 41.4504 82.8331 44.0301 83.9097 46.0748C85.4669 49.0269 87.9112 50.8685 90.6533 51.8503C92.3528 52.4528 94.1538 52.7237 95.9209 52.7237C97.6881 52.7237 99.4959 52.4461 101.189 51.8503C103.931 50.8753 106.375 49.0336 107.932 46.0748C109.009 44.0301 109.659 41.4572 109.679 38.2614V16.8321C109.679 15.9654 109.625 14.8212 109.869 12.6681C109.984 11.6457 110.295 10.84 110.356 10.4066C110.41 10.0139 110.106 9.66187 109.706 9.66187Z"
            fill="white"
          />
          <path
            d="M281.716 9.66187H276.293C275.718 9.66187 275.406 9.98009 275.406 11.0634V33.4271V37.8349C275.406 42.9806 272.4 46.4404 267.931 46.4404C263.463 46.4404 260.456 42.9806 260.456 37.8349V33.4271V16.8321V11.0634C260.456 9.98686 260.145 9.66187 259.569 9.66187H254.146C253.747 9.66187 253.442 10.0139 253.496 10.4066C253.557 10.8332 253.868 11.6389 253.983 12.6681C254.227 14.8212 254.173 15.9654 254.173 16.8321V38.2614C254.193 41.4504 254.843 44.0301 255.92 46.0748C257.477 49.0269 259.921 50.8685 262.664 51.8503C264.363 52.4528 266.164 52.7237 267.931 52.7237C269.698 52.7237 271.506 52.4461 273.199 51.8503C275.941 50.8753 278.385 49.0336 279.942 46.0748C281.019 44.0301 281.669 41.4572 281.689 38.2614V16.8321C281.689 15.9654 281.635 14.8212 281.879 12.6681C281.994 11.6457 282.305 10.84 282.366 10.4066C282.421 10.0139 282.116 9.66187 281.716 9.66187Z"
            fill="white"
          />
          <path
            d="M170.053 16.2431C170.399 16.1279 170.636 15.7962 170.636 15.4306V10.5353C170.649 10.2713 170.527 10.0208 170.318 9.85826C170.108 9.69576 169.837 9.63483 169.58 9.70253C169.451 9.73639 166.384 10.5218 163.784 12.0994C156.857 16.2904 150.953 23.5283 146.627 33.1021C142.3 23.5216 136.396 16.2837 129.47 12.0994C126.87 10.5218 123.803 9.73639 123.674 9.70253C123.417 9.63483 123.146 9.69576 122.936 9.85826C122.726 10.0208 122.604 10.2713 122.604 10.5353L122.618 15.4306C122.618 15.7962 122.855 16.1212 123.2 16.2431C124.155 16.568 125.265 16.9743 126.22 17.543C131.433 20.6372 135.645 26.2028 139.538 33.2443C143.16 39.7916 144.406 49.9545 144.616 51.4711C144.663 51.8029 144.948 52.0534 145.286 52.0534H147.994C148.333 52.0534 148.617 51.8029 148.665 51.4711C148.868 49.9545 150.12 39.7916 153.743 33.2443C157.636 26.2028 161.847 20.6372 167.061 17.543C168.015 16.9743 169.126 16.568 170.081 16.2431H170.053Z"
            fill="white"
          />
          <path
            d="M144.853 17.4686V19.8993C144.853 20.8742 145.645 21.6664 146.62 21.6664C147.595 21.6664 148.387 20.8742 148.387 19.8993V17.4686C148.387 16.4936 147.595 15.7014 146.62 15.7014C145.645 15.7014 144.853 16.4936 144.853 17.4686Z"
            fill="white"
          />
          <path
            d="M146.62 5.97184C147.595 5.97184 148.387 5.17967 148.387 4.20469V1.774C148.387 0.799011 147.595 0.00683594 146.62 0.00683594C145.645 0.00683594 144.853 0.799011 144.853 1.774V4.20469C144.853 5.17967 145.645 5.97184 146.62 5.97184Z"
            fill="white"
          />
          <path
            d="M153.682 12.6613H155.388C156.363 12.6613 157.155 11.8691 157.155 10.8941C157.155 9.91913 156.363 9.12695 155.388 9.12695H153.682C152.707 9.12695 151.915 9.91913 151.915 10.8941C151.915 11.8691 152.707 12.6613 153.682 12.6613Z"
            fill="white"
          />
          <path
            d="M137.852 12.6613H139.558C140.533 12.6613 141.325 11.8691 141.325 10.8941C141.325 9.91913 140.533 9.12695 139.558 9.12695H137.852C136.877 9.12695 136.085 9.91913 136.085 10.8941C136.085 11.8691 136.877 12.6613 137.852 12.6613Z"
            fill="white"
          />
          <path
            d="M142.165 15.1055C142.51 15.4711 142.977 15.6607 143.451 15.6607C143.891 15.6607 144.325 15.4982 144.67 15.1732L146.62 13.3248L148.57 15.1732C148.915 15.4982 149.349 15.6607 149.789 15.6607C150.256 15.6607 150.723 15.4779 151.075 15.1055C151.745 14.3946 151.718 13.2774 151.007 12.6071L149.193 10.8873L151.001 9.17436C151.712 8.50405 151.739 7.38011 151.068 6.67596C150.398 5.96503 149.274 5.93795 148.57 6.60825L146.627 8.44989L144.683 6.60825C143.973 5.93795 142.855 5.96503 142.185 6.67596C141.515 7.38688 141.542 8.50405 142.253 9.17436L144.061 10.8873L142.246 12.6071C141.535 13.2774 141.508 14.4014 142.178 15.1055H142.165Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_1139">
            <rect width="319.104" height="52.7236" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
