"use client";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

import Link from "next/link";
import Button from "./Button";
import Logo from "./icons/Logo";
import { useRef } from "react";
import Image from "./Image";

export default function Footer() {
  const ref = useRef(null);
  const container = useRef(null);
  useGSAP(
    () => {
      if (!gsap || !container.current) {
        return;
      }
      const tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: container.current,
          start: "top+=400 bottom",
          end: `bottom+=400 bottom`,
          scrub: 0.25,
        },
      });

      tl.to(ref.current, {
        opacity: 1,
        ease: "power3.inOut",
      });
    },
    {
      dependencies: [ref.current, container.current],
    }
  );
  return (
    <footer className="bg-[#304E63] md:bg-[#0C2340BF] relative">
      <img
        src="/mobile-footer.svg"
        className="w-full md:hidden h-full object-cover absolute z-0"
        alt="Illuminus"
      />
      <div className="relative z-[1] overflow-hidden h-max">
        <div className="flex flex-col lg:flex-row pl-gutter pr-25 gap-8 text-cream justify-end flex-wrap pb-20 pt-16 z-[1] relative overflow-hidden h-max md:bg-[#0C2340BF]">
          <svg
            className=" h-[200%] right-0 top-0 absolute z-[-1] mix-blend-darken md:block hidden"
            viewBox="0 0 1311 720"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M172.048 355L1823.05 355V0L172.048 0V355Z"
              fill="#9DCFCB"
            />
            <path
              d="M1347.99 696H1013V411.218C1013 285.177 1115.63 183 1242.23 183H1348"
              fill="#9DCFCB"
            />
            <path
              d="M1347.99 696H1013V411.218C1013 285.177 1115.63 183 1242.23 183H1348"
              stroke="#6F9BA4"
              strokeWidth="46.4"
              strokeMiterlimit="10"
            />
            <path
              d="M176.917 355C274.626 355 353.835 275.791 353.835 178.082C353.835 80.3736 274.626 1.16504 176.917 1.16504C79.2086 1.16504 0 80.3736 0 178.082C0 275.791 79.2086 355 176.917 355Z"
              fill="#9DCFCB"
            />
            <path
              d="M200.772 178.696L196.982 180.577C189.36 184.353 183.188 190.525 179.426 198.147L177.545 201.937C177.294 202.453 176.555 202.453 176.304 201.937L174.423 198.147C170.647 190.525 164.489 184.353 156.867 180.591L153.077 178.71C152.561 178.459 152.561 177.72 153.077 177.47L156.867 175.588C164.489 171.812 170.661 165.639 174.423 158.018L176.304 154.214C176.555 153.698 177.294 153.698 177.545 154.214L179.565 158.241C183.369 165.835 189.556 171.966 197.177 175.7L200.758 177.456C201.274 177.706 201.274 178.445 200.758 178.696H200.772Z"
              fill="#6F9BA4"
            />
            <path
              d="M127.885 178.696L124.095 180.577C116.473 184.353 110.3 190.525 106.538 198.147L104.657 201.937C104.406 202.453 103.668 202.453 103.417 201.937L101.536 198.147C97.7599 190.525 91.6012 184.353 83.9794 180.591L80.1894 178.71C79.6738 178.459 79.6738 177.72 80.1894 177.47L83.9794 175.588C91.6012 171.812 97.7739 165.639 101.536 158.018L103.417 154.214C103.668 153.698 104.406 153.698 104.657 154.214L106.678 158.241C110.481 165.835 116.668 171.966 124.29 175.7L127.871 177.456C128.386 177.706 128.386 178.445 127.871 178.696H127.885Z"
              fill="#6F9BA4"
            />
            <path
              d="M273.66 178.696L269.87 180.577C262.248 184.353 256.076 190.525 252.314 198.147L250.432 201.937C250.182 202.453 249.443 202.453 249.192 201.937L247.311 198.147C243.535 190.525 237.376 184.353 229.755 180.591L225.965 178.71C225.449 178.459 225.449 177.72 225.965 177.47L229.755 175.588C237.376 171.812 243.549 165.639 247.311 158.018L249.192 154.214C249.443 153.698 250.182 153.698 250.432 154.214L252.453 158.241C256.257 165.835 262.443 171.966 270.065 175.7L273.646 177.456C274.162 177.706 274.162 178.445 273.646 178.696H273.66Z"
              fill="#6F9BA4"
            />
            <path
              d="M177.545 227.115L179.426 230.905C183.202 238.527 189.374 244.699 196.996 248.462L200.786 250.342C201.302 250.593 201.302 251.332 200.786 251.583L196.996 253.464C189.374 257.24 183.202 263.399 179.439 271.02L177.558 274.81C177.308 275.326 176.569 275.326 176.318 274.81L174.437 271.02C170.661 263.399 164.489 257.226 156.867 253.464L153.063 251.583C152.547 251.332 152.547 250.593 153.063 250.342L157.09 248.322C164.684 244.518 170.815 238.332 174.549 230.71L176.304 227.129C176.555 226.613 177.294 226.613 177.545 227.129V227.115Z"
              fill="#6F9BA4"
            />
            <path
              d="M177.544 81.339L179.425 85.1293C183.201 92.7511 189.374 98.9236 196.996 102.686L200.786 104.567C201.301 104.818 201.301 105.556 200.786 105.807L196.996 107.688C189.374 111.464 183.201 117.623 179.439 125.244L177.558 129.035C177.307 129.55 176.569 129.55 176.318 129.035L174.437 125.244C170.661 117.623 164.488 111.45 156.867 107.688L153.063 105.807C152.547 105.556 152.547 104.818 153.063 104.567L157.089 102.547C164.683 98.7426 170.814 92.5558 174.549 84.9341L176.304 81.353C176.555 80.8374 177.293 80.8374 177.544 81.353V81.339Z"
              fill="#6F9BA4"
            />
          </svg>

          <div className="stack gap-6">
            <h2>ILLUMINUS</h2>
            <address className="not-italic">
              2979 Triverton Pike Drive
              <br />
              Fitchburg, WI 53711
            </address>
            <Link href="tel:+19202610400">(920) 261-0400</Link>
          </div>
          <div className="stack gap-6">
            <h2>
              <Link href="/communities" className="font-bold uppercase">
                Communities
              </Link>
            </h2>
            <ul className="footer-ul">
              <li>
                <Link href="/communities/the-marquardt">The Marquardt</Link>
              </li>
              <li>
                <Link href="/communities/lutheran-homes-and-health-services">
                  Lutheran Homes & Health Services
                </Link>
              </li>
              <li>
                <Link href="/communities/lindengrove-new-berlin">
                  LindenGrove New Berlin
                </Link>
              </li>
              <li>
                <Link href="/communities/lindengrove-waukesha">
                  LindenGrove Waukesha
                </Link>
              </li>
              <li>
                <Link href="/communities/lindengrove-mukwonago">
                  LindenGrove Mukwonago
                </Link>
              </li>
              <li>
                <Link href="/communities/lindengrove-menomonee-falls">
                  LindenGrove Menomonee Falls
                </Link>
              </li>
              <li>
                <Link href="/communities/st-elizabeth">St. Elizabeth</Link>
              </li>
              <li>
                <Link href="/communities/st-dominic-villa">
                  St. Dominic Villa
                </Link>
              </li>
              <li>
                <Link href="/communities/st-annes">St. Anne's</Link>
              </li>
              <li>
                <Link href="https://thecesta.org/">The Cesta</Link>
              </li>
            </ul>
          </div>
          <div className="stack gap-6">
            <h2>
              <Link href="/lifestyle-options" className="font-bold uppercase">
                Lifestyle Options
              </Link>
            </h2>
            <ul className="footer-ul">
              <li>
                <Link href="/lifestyle-options/independent-living">
                  Independent Living
                </Link>
              </li>
              <li>
                <Link href="/lifestyle-options/assisted-living">
                  Assisted Living
                </Link>
              </li>
              <li>
                <Link href="/lifestyle-options/skilled-nursing-care">
                  Skilled Nursing
                </Link>
              </li>
              <li>
                <Link href="/lifestyle-options/rehab">Rehab Therapies</Link>
              </li>
              <li>
                <Link href="/lifestyle-options/affordable-housing">
                  Affordable Housing
                </Link>
              </li>
              <li>
                <Link href="https://commonheart.com/">Commonheart</Link>
              </li>
            </ul>
          </div>
          <div className="stack gap-6 pt-[56px]">
            <ul>
              <li>
                <Link href="/careers">Careers</Link>
              </li>
              <li>
                <Link href="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link href="/about-us">About</Link>
              </li>
              <li>
                <Link href="/bill-pay">Bill Pay</Link>
              </li>
              <li>
                <Link href="/donating">Donate</Link>
              </li>
              <li>
                <Link href="/illuminus-institute">Illuminus Institute</Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="grid place-content-center w-full py-40 relative md:bg-[#0C2340BF]"
          ref={container}
        >
          <svg
            className=" w-[100dvw] right-0 top-[-20px] absolute z-[-1] mix-blend-darken md:block hidden"
            viewBox="0 0 1440 578"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M286.987 577.04C128.484 577.04 0 448.763 0 290.54C0 132.306 128.495 4.04004 286.987 4.04004H1404V577.04H286.987Z"
              fill="#10223E"
            />
            <path
              d="M1460.4 575.962L1460.3 0.770508L940.752 0.861186L940.853 576.053L1460.4 575.962Z"
              fill="#6F9BA4"
            />
            <path
              d="M1151.45 491.587C1263.67 379.365 1263.67 197.418 1151.45 85.1959C1039.23 -27.026 857.278 -27.0261 745.056 85.1958C632.834 197.418 632.834 379.365 745.056 491.587C857.278 603.809 1039.23 603.809 1151.45 491.587Z"
              fill="#6F9BA4"
            />
            <path
              d="M524.933 271.285H94.2793"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M511.934 197.55L107.247 344.842"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M474.495 132.711L144.603 409.526"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M417.147 84.5903L201.815 457.543"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M346.79 58.9766L272.006 483.094"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M271.913 58.9766L346.697 483.094"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M201.566 84.5903L416.888 457.543"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M144.208 132.711L474.111 409.526"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M106.77 197.55L511.456 344.842"
              stroke="#9DCFCB"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M351.012 312.682C373.879 289.815 373.879 252.742 351.013 229.875C328.146 207.009 291.072 207.009 268.206 229.875C245.339 252.741 245.34 289.815 268.206 312.682C291.072 335.548 328.146 335.548 351.012 312.682Z"
              fill="#9DCFCB"
            />
            <path
              d="M982.801 273.259L977.314 275.971C966.279 281.437 957.333 290.373 951.877 301.419L949.155 306.915C948.791 307.663 947.721 307.663 947.357 306.915L944.635 301.419C939.18 290.373 930.233 281.437 919.198 275.981L913.701 273.259C912.953 272.895 912.953 271.825 913.701 271.461L919.198 268.739C930.233 263.273 939.18 254.337 944.635 243.291L947.357 237.784C947.721 237.047 948.781 237.036 949.155 237.784L952.075 243.624C957.582 254.628 966.549 263.512 977.595 268.916L982.791 271.461C983.539 271.825 983.539 272.895 982.791 273.259H982.801Z"
              fill="#9DCFCB"
            />
            <path
              d="M877.219 273.259L871.732 275.971C860.697 281.437 851.75 290.373 846.295 301.419L843.573 306.915C843.209 307.663 842.139 307.663 841.775 306.915L839.053 301.419C833.597 290.373 824.651 281.437 813.615 275.981L808.119 273.259C807.371 272.895 807.371 271.825 808.119 271.461L813.615 268.739C824.651 263.273 833.597 254.337 839.053 243.291L841.775 237.784C842.139 237.047 843.199 237.036 843.573 237.784L846.493 243.624C852 254.628 860.967 263.512 872.013 268.916L877.208 271.461C877.956 271.825 877.956 272.895 877.208 273.259H877.219Z"
              fill="#9DCFCB"
            />
            <path
              d="M1088.38 273.259L1082.9 275.971C1071.86 281.437 1062.91 290.373 1057.46 301.419L1054.74 306.915C1054.37 307.663 1053.3 307.663 1052.94 306.915L1050.22 301.419C1044.76 290.373 1035.82 281.437 1024.78 275.981L1019.28 273.259C1018.54 272.895 1018.54 271.825 1019.28 271.461L1024.78 268.739C1035.82 263.273 1044.76 254.337 1050.22 243.291L1052.94 237.784C1053.3 237.047 1054.36 237.036 1054.74 237.784L1057.66 243.624C1063.16 254.628 1072.13 263.512 1083.18 268.916L1088.37 271.461C1089.12 271.825 1089.12 272.895 1088.37 273.259H1088.38Z"
              fill="#9DCFCB"
            />
            <path
              d="M949.156 343.388L951.868 348.875C957.333 359.91 966.269 368.856 977.315 374.312L982.812 377.034C983.56 377.398 983.56 378.468 982.812 378.832L977.315 381.554C966.269 387.009 957.333 395.956 951.878 406.991L949.156 412.488C948.792 413.236 947.722 413.236 947.358 412.488L944.635 406.991C939.17 395.956 930.234 387.009 919.188 381.554L913.681 378.832C912.943 378.468 912.933 377.408 913.681 377.034L919.52 374.114C930.525 368.607 939.409 359.64 944.812 348.594L947.358 343.399C947.722 342.65 948.792 342.65 949.156 343.399V343.388Z"
              fill="#9DCFCB"
            />
            <path
              d="M949.155 132.223L951.867 137.709C957.333 148.744 966.269 157.691 977.315 163.146L982.812 165.868C983.56 166.232 983.56 167.303 982.812 167.666L977.315 170.389C966.269 175.844 957.333 184.79 951.878 195.826L949.155 201.323C948.792 202.071 947.721 202.071 947.358 201.323L944.635 195.826C939.17 184.79 930.233 175.844 919.188 170.389L913.681 167.666C912.943 167.303 912.932 166.242 913.681 165.868L919.52 162.949C930.524 157.442 939.409 148.474 944.812 137.428L947.358 132.233C947.721 131.485 948.792 131.485 949.155 132.233V132.223Z"
              fill="#9DCFCB"
            />
          </svg>
          <div className="relative">
            <svg
              viewBox="0 0 943 203"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=" blur-[15px] absolute z-0 top-0 left-0 opacity-0 scale-105 w-[80dvw]"
              ref={ref}
            >
              <path
                d="M294.213 168.539C309.542 168.539 320.234 156.458 320.234 139.153V61.5163C320.234 60.8685 319.978 60.2472 319.522 59.7891C319.067 59.3311 318.449 59.0737 317.804 59.0737C317.16 59.0737 316.541 59.3311 316.086 59.7891C315.63 60.2472 315.374 60.8685 315.374 61.5163V139.153C315.374 153.809 306.869 163.579 294.213 163.579C281.558 163.579 273.052 153.752 273.052 139.153V61.5163C273.052 60.8685 272.796 60.2472 272.341 59.7891C271.885 59.3311 271.267 59.0737 270.622 59.0737C269.978 59.0737 269.36 59.3311 268.904 59.7891C268.448 60.2472 268.192 60.8685 268.192 61.5163V139.153C268.192 156.458 278.885 168.539 294.213 168.539Z"
                fill="#FFC886"
              />
              <path
                d="M159.154 162.602H113.486V61.5163C113.486 60.8685 113.23 60.2472 112.775 59.7891C112.319 59.3311 111.701 59.0737 111.056 59.0737C110.412 59.0737 109.794 59.3311 109.338 59.7891C108.882 60.2472 108.626 60.8685 108.626 61.5163V167.487H159.098C159.743 167.487 160.361 167.23 160.816 166.772C161.272 166.314 161.528 165.692 161.528 165.045C161.528 164.397 161.272 163.776 160.816 163.317C160.361 162.859 159.743 162.602 159.098 162.602H159.154Z"
                fill="#FFC886"
              />
              <path
                d="M153.285 174.739H105.786C105.141 174.739 104.523 174.996 104.067 175.454C103.612 175.912 103.356 176.534 103.356 177.181C103.356 177.829 103.612 178.45 104.067 178.909C104.523 179.367 105.141 179.624 105.786 179.624H153.285C153.93 179.624 154.548 179.367 155.004 178.909C155.459 178.45 155.716 177.829 155.716 177.181C155.716 176.534 155.459 175.912 155.004 175.454C154.548 174.996 153.93 174.739 153.285 174.739Z"
                fill="#FFC886"
              />
              <path
                d="M248.229 174.739H191.981C191.336 174.739 190.718 174.996 190.262 175.454C189.807 175.912 189.551 176.534 189.551 177.181C189.551 177.829 189.807 178.45 190.262 178.909C190.718 179.367 191.336 179.624 191.981 179.624H248.229C248.873 179.624 249.491 179.367 249.947 178.909C250.403 178.45 250.659 177.829 250.659 177.181C250.659 176.534 250.403 175.912 249.947 175.454C249.491 174.996 248.873 174.739 248.229 174.739Z"
                fill="#FFC886"
              />
              <path
                d="M244.115 165.045C244.115 164.397 243.859 163.776 243.404 163.317C242.948 162.859 242.33 162.602 241.685 162.602H199.681V61.5163C199.681 61.1956 199.619 60.8779 199.496 60.5816C199.374 60.2852 199.195 60.016 198.97 59.7891C198.744 59.5623 198.476 59.3824 198.181 59.2597C197.886 59.1369 197.57 59.0737 197.251 59.0737C196.932 59.0737 196.616 59.1369 196.321 59.2597C196.026 59.3824 195.759 59.5623 195.533 59.7891C195.307 60.016 195.128 60.2852 195.006 60.5816C194.884 60.8779 194.821 61.1956 194.821 61.5163V167.487H241.704C242.345 167.482 242.959 167.223 243.41 166.765C243.862 166.308 244.115 165.689 244.115 165.045Z"
                fill="#FFC886"
              />
              <path
                d="M849.91 121.735C855.686 125.305 863.874 127.184 870.304 128.405C873.595 129.119 882.866 131.411 886.287 133.516C889.708 135.62 895.447 140.524 893.765 148.998C893.048 152.663 891.165 155.994 888.4 158.488C885.635 160.983 882.138 162.506 878.436 162.827C871.882 163.528 865.264 162.369 859.332 159.482C854.244 156.831 849.571 153.444 845.461 149.43L844.825 148.829C844.347 148.393 843.716 148.166 843.071 148.198C842.426 148.229 841.82 148.517 841.386 148.998C840.952 149.479 840.726 150.113 840.758 150.762C840.789 151.41 841.076 152.019 841.554 152.455L842.115 152.981C846.559 157.299 851.595 160.956 857.07 163.842C862.571 166.542 868.612 167.949 874.735 167.956C876.173 167.959 877.609 167.865 879.034 167.675C882.866 167.167 895.54 164.405 898.494 149.975C900.905 138.157 892.531 131.599 888.811 129.307C884.007 126.376 871.837 123.67 871.258 123.67C865.182 122.524 857.556 120.796 852.452 117.639C847.726 114.706 844.023 110.37 841.853 105.231C839.684 100.092 839.155 94.4023 840.339 88.948C841.318 83.6643 843.66 78.7315 847.131 74.6429C850.602 70.5543 855.08 67.4531 860.117 65.6494C869.875 61.7788 880.829 62.7183 892.662 68.4678C898.27 71.2298 903.093 72.2256 907.205 71.6056C907.521 71.5562 907.823 71.4449 908.096 71.278C908.369 71.111 908.606 70.8918 908.794 70.6327C908.982 70.3736 909.118 70.0797 909.194 69.7679C909.269 69.4561 909.282 69.1324 909.233 68.8154C909.184 68.4983 909.073 68.1941 908.907 67.92C908.741 67.646 908.523 67.4075 908.265 67.2182C908.008 67.0289 907.715 66.8925 907.405 66.8167C907.095 66.741 906.773 66.7274 906.457 66.7768C903.336 67.2653 899.522 66.3822 894.774 64.0711C878.455 56.1421 865.949 58.0774 858.322 61.1024C852.53 63.1915 847.383 66.7697 843.394 71.4806C839.406 76.1915 836.716 81.8706 835.591 87.9521C834.261 94.3791 834.92 101.061 837.479 107.101C840.039 113.14 844.377 118.247 849.91 121.735Z"
                fill="#FFC886"
              />
              <path
                d="M896.754 118.767C890.847 115.16 882.865 113.356 876.584 112.21C873.051 111.533 863.163 109.316 859.76 107.174C857.316 105.668 855.367 103.471 854.157 100.858C852.947 98.2446 852.529 95.3313 852.956 92.4812C853.409 89.0083 854.826 85.7338 857.045 83.0324C859.263 80.3309 862.193 78.3117 865.499 77.2056C878.585 72.8278 888.922 79.8737 896.493 85.0219L897.67 85.8111C898.206 86.1724 898.862 86.3051 899.495 86.18C900.128 86.0549 900.685 85.6822 901.045 85.1441C901.404 84.6059 901.536 83.9462 901.412 83.3102C901.287 82.6742 900.916 82.1139 900.381 81.7526L899.222 80.9635C891.296 75.571 879.314 67.4353 863.966 72.5647C859.786 73.9634 856.083 76.5169 853.279 79.9328C850.476 83.3488 848.686 87.4892 848.115 91.88C847.586 95.6493 848.158 99.4918 849.761 102.941C851.363 106.39 853.928 109.296 857.143 111.308C861.854 114.314 874.21 116.738 875.612 116.945C881.501 118.016 888.922 119.669 894.137 122.863C903.764 128.744 908.232 139.229 906.101 150.897C904.531 159.559 900.605 166.06 894.437 170.156C883.893 177.22 872.191 177.972 858.658 172.523C855.422 171.124 852.27 169.537 849.217 167.769C846.638 166.341 844.189 165.007 841.89 164.012C839.189 162.757 836.247 162.116 833.272 162.133C832.628 162.133 832.009 162.39 831.554 162.848C831.098 163.306 830.842 163.927 830.842 164.575C830.842 165.223 831.098 165.844 831.554 166.302C832.009 166.761 832.628 167.018 833.272 167.018C835.545 167 837.796 167.474 839.871 168.408C842.002 169.348 844.357 170.644 846.843 172.016C850.063 173.88 853.39 175.549 856.807 177.014C862.687 179.503 868.99 180.83 875.369 180.922C883.171 181.003 890.812 178.697 897.278 174.308C904.587 169.442 909.204 161.888 911.036 151.761C913.466 138.101 908.157 125.738 896.754 118.767Z"
                fill="#FFC886"
              />
              <path
                d="M383.213 95.3545C382.569 95.3545 381.951 95.6118 381.495 96.0699C381.039 96.528 380.783 97.1493 380.783 97.7971V177.181C380.783 177.829 381.039 178.45 381.495 178.908C381.951 179.367 382.569 179.624 383.213 179.624C383.858 179.624 384.476 179.367 384.932 178.908C385.387 178.45 385.643 177.829 385.643 177.181V97.7971C385.643 97.1493 385.387 96.528 384.932 96.0699C384.476 95.6118 383.858 95.3545 383.213 95.3545Z"
                fill="#FFC886"
              />
              <path
                d="M502.139 74.649C501.93 74.0361 501.488 73.5316 500.91 73.2462C500.331 72.9608 499.664 72.9179 499.054 73.1271C495.762 74.1657 492.586 75.5447 489.577 77.2419C466.883 90.7889 448.171 118.897 436.88 156.401C436.076 159.106 435.16 162.244 434.301 165.607C433.422 162.244 432.431 159.106 431.702 156.401C420.486 118.822 401.699 90.7889 379.006 77.2419C375.998 75.5414 372.822 74.1622 369.528 73.1271C369.222 73.0043 368.893 72.9455 368.563 72.9545C368.233 72.9635 367.909 73.0399 367.609 73.1792C367.309 73.3184 367.041 73.5176 366.82 73.7645C366.6 74.0114 366.432 74.3009 366.326 74.6153C366.221 74.9297 366.18 75.2624 366.207 75.5932C366.233 75.9239 366.327 76.2457 366.481 76.539C366.636 76.8322 366.848 77.0908 367.105 77.2989C367.362 77.5069 367.659 77.6602 367.977 77.7492C370.945 78.7046 373.807 79.9638 376.52 81.507C398.166 94.3776 416.112 121.49 427.048 157.829C429.055 164.308 430.647 170.91 431.814 177.595C431.902 178.172 432.193 178.698 432.634 179.078C433.074 179.458 433.636 179.667 434.216 179.667C434.797 179.667 435.359 179.458 435.799 179.078C436.24 178.698 436.531 178.172 436.619 177.595C437.853 170.911 439.495 164.31 441.535 157.829C452.47 121.509 470.416 94.3964 492.007 81.4695C494.719 79.9262 497.582 78.667 500.55 77.7116C501.164 77.5172 501.677 77.0854 501.975 76.511C502.273 75.9367 502.332 75.2669 502.139 74.649Z"
                fill="#FFC886"
              />
              <path
                d="M368.127 63.8824C373.402 65.3078 378.472 67.4107 383.212 70.1392C403.046 82.1831 419.87 103.697 431.815 132.35C432.017 132.83 432.355 133.239 432.788 133.526C433.22 133.814 433.727 133.967 434.245 133.966C434.763 133.964 435.268 133.807 435.698 133.516C436.127 133.225 436.461 132.813 436.657 132.331C448.658 103.697 465.482 82.1831 485.259 70.1204C490.04 67.3991 495.147 65.3028 500.457 63.8824C500.766 63.8035 501.057 63.6641 501.313 63.4721C501.568 63.2802 501.784 63.0396 501.947 62.7639C502.11 62.4882 502.218 62.1829 502.264 61.8655C502.309 61.548 502.292 61.2246 502.214 60.9137C502.146 60.5939 502.014 60.2911 501.828 60.0228C501.642 59.7545 501.404 59.5262 501.129 59.3513C500.854 59.1764 500.548 59.0583 500.227 59.0039C499.906 58.9496 499.577 58.9601 499.26 59.0348C493.516 60.577 487.998 62.8701 482.848 65.8553C463.257 77.7676 446.545 98.3605 434.245 125.605C422.001 98.3605 405.27 77.7676 385.642 65.8553C380.492 62.8701 374.974 60.577 369.23 59.0348C368.919 58.9559 368.596 58.9392 368.279 58.9857C367.962 59.0322 367.658 59.141 367.382 59.3058C367.107 59.4707 366.867 59.6884 366.676 59.9466C366.484 60.2048 366.345 60.4983 366.267 60.8104C366.188 61.1225 366.172 61.4472 366.218 61.7657C366.264 62.0843 366.372 62.3906 366.537 62.6672C366.701 62.9437 366.917 63.1851 367.174 63.3775C367.431 63.5699 367.723 63.7095 368.033 63.7885L368.127 63.8824Z"
                fill="#FFC886"
              />
              <path
                d="M431.815 74.6113V95.0351C431.868 95.6483 432.149 96.2192 432.6 96.6351C433.052 97.0509 433.642 97.2816 434.254 97.2816C434.867 97.2816 435.457 97.0509 435.909 96.6351C436.36 96.2192 436.64 95.6483 436.694 95.0351V74.6113C436.64 73.998 436.36 73.4272 435.909 73.0113C435.457 72.5954 434.867 72.3647 434.254 72.3647C433.642 72.3647 433.052 72.5954 432.6 73.0113C432.149 73.4272 431.868 73.998 431.815 74.6113Z"
                fill="#FFC886"
              />
              <path
                d="M434.302 46.7096C434.621 46.7096 434.937 46.6464 435.232 46.5237C435.527 46.4009 435.795 46.221 436.021 45.9942C436.246 45.7674 436.425 45.4981 436.547 45.2018C436.669 44.9054 436.732 44.5878 436.732 44.267V23.8432C436.679 23.23 436.399 22.6591 435.947 22.2432C435.496 21.8274 434.905 21.5967 434.293 21.5967C433.68 21.5967 433.09 21.8274 432.639 22.2432C432.187 22.6591 431.907 23.23 431.853 23.8432V44.267C431.858 44.9166 432.118 45.5378 432.577 45.9954C433.036 46.4529 433.656 46.7096 434.302 46.7096Z"
                fill="#FFC886"
              />
              <path
                d="M447.648 59.6743C447.648 60.3271 447.906 60.9532 448.366 61.4148C448.825 61.8763 449.448 62.1357 450.097 62.1357H464.173C464.818 62.1357 465.436 61.8783 465.892 61.4203C466.347 60.9622 466.603 60.3409 466.603 59.6931C466.603 59.0453 466.347 58.424 465.892 57.9659C465.436 57.5078 464.818 57.2505 464.173 57.2505H450.097C449.454 57.2504 448.837 57.5046 448.379 57.9581C447.921 58.4116 447.658 59.028 447.648 59.6743Z"
                fill="#FFC886"
              />
              <path
                d="M404.43 62.1353H418.487C419.132 62.1353 419.75 61.878 420.206 61.4199C420.661 60.9618 420.917 60.3405 420.917 59.6927C420.917 59.0449 420.661 58.4236 420.206 57.9655C419.75 57.5075 419.132 57.2501 418.487 57.2501H404.43C404.092 57.2203 403.751 57.2616 403.429 57.3713C403.108 57.481 402.812 57.6567 402.562 57.8873C402.311 58.1179 402.112 58.3983 401.975 58.7107C401.838 59.0232 401.767 59.3608 401.767 59.7021C401.767 60.0435 401.838 60.3811 401.975 60.6935C402.112 61.0059 402.311 61.2863 402.562 61.5169C402.812 61.7475 403.108 61.9233 403.429 62.0329C403.751 62.1426 404.092 62.1839 404.43 62.1541V62.1353Z"
                fill="#FFC886"
              />
              <path
                d="M430.75 59.6559L419.01 70.9294C418.658 71.2598 418.411 71.6881 418.302 72.1599C418.192 72.6316 418.224 73.1254 418.395 73.5785C418.565 74.0317 418.866 74.4237 419.258 74.7047C419.651 74.9858 420.117 75.1432 420.599 75.1569C421.226 75.1599 421.83 74.9172 422.282 74.4805L434.227 63.1131L446.153 74.4805C446.606 74.9156 447.209 75.1579 447.835 75.1569C448.323 75.1579 448.8 75.0111 449.204 74.7357C449.608 74.4603 449.92 74.069 450.1 73.6128C450.279 73.1566 450.318 72.6566 450.211 72.178C450.104 71.6994 449.856 71.2643 449.499 70.9294L437.76 59.6559L449.462 48.5139C449.694 48.2931 449.88 48.0285 450.01 47.7352C450.141 47.442 450.212 47.1258 450.221 46.8048C450.229 46.4837 450.175 46.1641 450.061 45.8642C449.947 45.5643 449.775 45.2899 449.555 45.0567C449.335 44.8235 449.072 44.6361 448.78 44.5052C448.489 44.3743 448.174 44.3024 447.855 44.2937C447.535 44.285 447.217 44.3396 446.919 44.4544C446.62 44.5692 446.348 44.7419 446.116 44.9628L434.227 56.2362L422.319 44.9628C422.088 44.7393 421.816 44.5642 421.517 44.4477C421.218 44.3313 420.9 44.2758 420.58 44.2846C420.259 44.2933 419.944 44.3661 419.652 44.4987C419.36 44.6313 419.098 44.8209 418.879 45.0567C418.427 45.5173 418.174 46.1383 418.174 46.7853C418.174 47.4323 418.427 48.0533 418.879 48.5139L430.75 59.6559Z"
                fill="#FFC886"
              />
              <path
                d="M694.363 59.0537C693.717 59.0587 693.099 59.3202 692.644 59.7812C692.188 60.2423 691.933 60.8655 691.933 61.5151V177.181C691.933 177.829 692.189 178.45 692.645 178.908C693.1 179.366 693.719 179.624 694.363 179.624C695.008 179.624 695.626 179.366 696.081 178.908C696.537 178.45 696.793 177.829 696.793 177.181V61.5151C696.793 60.8655 696.538 60.2423 696.082 59.7812C695.627 59.3202 695.009 59.0587 694.363 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M653.33 117.846C644.916 106.683 635.08 96.6765 624.075 88.0844C616.593 82.1177 608.359 77.1709 599.587 73.3725C599.286 73.2356 598.961 73.1619 598.631 73.1557C598.3 73.1495 597.972 73.2111 597.667 73.3366C597.361 73.4621 597.084 73.6489 596.852 73.8856C596.621 74.1224 596.44 74.404 596.32 74.7135C596.201 75.0229 596.145 75.3536 596.157 75.6853C596.169 76.0171 596.248 76.3429 596.389 76.6429C596.53 76.943 596.731 77.2109 596.979 77.4304C597.226 77.6499 597.516 77.8163 597.83 77.9195C606.184 81.5876 614.021 86.3445 621.141 92.0677C631.795 100.401 641.322 110.097 649.48 120.909C658.628 132.552 665.595 145.769 670.042 159.915C671.854 165.761 673.352 171.701 674.529 177.709C674.633 178.269 674.93 178.775 675.367 179.139C675.804 179.503 676.354 179.701 676.921 179.7C677.07 179.718 677.221 179.718 677.37 179.7C677.991 179.565 678.535 179.19 678.885 178.656C679.234 178.122 679.361 177.472 679.239 176.844C678.03 170.665 676.489 164.556 674.622 158.544C670.038 143.793 662.823 130.003 653.33 117.846Z"
                fill="#FFC886"
              />
              <path
                d="M681.203 59.0537C680.556 59.0587 679.938 59.3202 679.483 59.7812C679.028 60.2423 678.773 60.8655 678.773 61.5151V133.496C672.648 121.149 664.778 109.757 655.406 99.6759C645.408 88.5808 633.993 78.864 621.459 70.7781C614.391 66.446 607.055 62.5738 599.494 59.1852C599.191 59.0816 598.871 59.039 598.551 59.0599C598.231 59.0807 597.919 59.1647 597.632 59.3069C597.345 59.4491 597.088 59.6467 596.877 59.8886C596.665 60.1305 596.504 60.4118 596.401 60.7165C596.297 61.0213 596.255 61.3434 596.276 61.6646C596.297 61.9858 596.38 62.2997 596.522 62.5885C596.663 62.8773 596.86 63.1352 597.1 63.3475C597.341 63.5599 597.621 63.7225 597.924 63.8262C605.122 67.0949 612.114 70.8033 618.861 74.9306C631.045 82.8247 642.138 92.3011 651.854 103.114C663.382 115.562 672.561 130.015 678.941 145.766C679.126 146.216 679.439 146.601 679.842 146.872C680.245 147.143 680.718 147.287 681.203 147.288C681.358 147.306 681.515 147.306 681.67 147.288C682.204 147.16 682.679 146.855 683.019 146.422C683.358 145.989 683.542 145.453 683.539 144.901V61.5151C683.54 60.8814 683.297 60.2718 682.862 59.8133C682.427 59.3548 681.833 59.0827 681.203 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M34.5631 59.6368C34.0673 59.2207 33.4274 59.0196 32.7841 59.0777C32.1408 59.1359 31.5469 59.4485 31.1329 59.9468C30.7189 60.4451 30.5188 61.0883 30.5767 61.7349C30.6345 62.3815 30.9456 62.9785 31.4413 63.3946C34.0771 65.5929 37.0493 68.0919 37.0493 79.6472V159.069C37.0493 170.606 34.1332 173.105 31.4413 175.303C31.0603 175.627 30.7868 176.06 30.6576 176.545C30.5284 177.029 30.5496 177.542 30.7184 178.014C30.8872 178.486 31.1955 178.895 31.602 179.187C32.0085 179.478 32.4938 179.637 32.9929 179.643C33.5687 179.644 34.1257 179.437 34.5631 179.061C37.6662 176.468 42.0404 172.879 41.8535 159.05V79.666C41.947 65.762 37.6662 62.2297 34.5631 59.6368Z"
                fill="#FFC886"
              />
              <path
                d="M97.916 59.0537C97.2698 59.0587 96.6517 59.3202 96.1965 59.7812C95.7413 60.2423 95.4859 60.8655 95.4859 61.5151V159.068C95.4859 170.605 92.5697 173.104 89.8779 175.302C89.4969 175.626 89.2234 176.06 89.0942 176.544C88.965 177.029 88.9862 177.542 89.155 178.014C89.3238 178.486 89.6321 178.895 90.0386 179.186C90.4451 179.477 90.9304 179.636 91.4294 179.643C92.0003 179.647 92.5528 179.44 92.981 179.06C96.1028 176.467 100.458 172.878 100.29 159.05V61.5151C100.29 60.8751 100.043 60.2602 99.5995 59.8007C99.1563 59.3412 98.5524 59.0733 97.916 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M184.111 59.0537C183.465 59.0587 182.847 59.3202 182.391 59.7812C181.936 60.2423 181.681 60.8655 181.681 61.5151V159.068C181.681 170.605 178.765 173.104 176.073 175.302C175.692 175.626 175.418 176.06 175.289 176.544C175.16 177.029 175.181 177.542 175.35 178.014C175.519 178.486 175.827 178.895 176.234 179.186C176.64 179.477 177.125 179.636 177.624 179.643C178.195 179.644 178.746 179.437 179.176 179.06C182.298 176.467 186.653 172.878 186.485 159.05V61.5151C186.485 60.8751 186.238 60.2602 185.794 59.8007C185.351 59.3412 184.747 59.0733 184.111 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M370.128 86.0352C369.484 86.0352 368.866 86.2925 368.41 86.7506C367.954 87.2086 367.698 87.8299 367.698 88.4777V159.069C367.698 170.605 364.782 173.104 362.09 175.302C361.709 175.627 361.436 176.06 361.306 176.544C361.177 177.029 361.198 177.542 361.367 178.014C361.536 178.486 361.844 178.895 362.251 179.186C362.657 179.477 363.143 179.637 363.642 179.643C364.212 179.644 364.763 179.437 365.193 179.06C368.315 176.467 372.671 172.879 372.502 159.05V88.4777C372.503 87.8396 372.254 87.2267 371.811 86.7702C371.367 86.3137 370.763 86.0499 370.128 86.0352Z"
                fill="#FFC886"
              />
              <path
                d="M485.352 95.3545C484.709 95.3594 484.094 95.6183 483.639 96.0753C483.185 96.5324 482.927 97.1508 482.922 97.7971V177.181C482.893 177.521 482.934 177.864 483.043 178.187C483.152 178.51 483.327 178.807 483.556 179.059C483.786 179.311 484.065 179.512 484.375 179.649C484.686 179.787 485.022 179.858 485.362 179.858C485.701 179.858 486.037 179.787 486.348 179.649C486.659 179.512 486.938 179.311 487.167 179.059C487.397 178.807 487.571 178.51 487.681 178.187C487.79 177.864 487.831 177.521 487.801 177.181V97.7971C487.796 97.1475 487.536 96.5263 487.077 96.0688C486.619 95.6112 485.999 95.3545 485.352 95.3545Z"
                fill="#FFC886"
              />
              <path
                d="M500.981 159.051V88.4785C500.928 87.8652 500.648 87.2944 500.196 86.8785C499.745 86.4626 499.154 86.2319 498.542 86.2319C497.93 86.2319 497.339 86.4626 496.888 86.8785C496.436 87.2944 496.156 87.8652 496.103 88.4785V159.032C496.009 172.879 500.29 176.468 503.412 179.061C503.842 179.438 504.393 179.645 504.963 179.643C505.469 179.653 505.965 179.505 506.383 179.219C506.801 178.934 507.122 178.526 507.3 178.05C507.478 177.575 507.505 177.055 507.378 176.563C507.251 176.072 506.975 175.631 506.589 175.303C503.86 173.105 500.869 170.606 500.981 159.051Z"
                fill="#FFC886"
              />
              <path
                d="M613.029 95.3545C612.384 95.3545 611.766 95.6118 611.31 96.0699C610.854 96.528 610.599 97.1493 610.599 97.7971V177.181C610.599 177.829 610.854 178.45 611.31 178.908C611.766 179.367 612.384 179.624 613.029 179.624C613.673 179.624 614.291 179.367 614.747 178.908C615.203 178.45 615.459 177.829 615.459 177.181V97.7971C615.459 97.1493 615.203 96.528 614.747 96.0699C614.291 95.6118 613.673 95.3545 613.029 95.3545Z"
                fill="#FFC886"
              />
              <path
                d="M599.868 86.0352C599.224 86.0352 598.606 86.2925 598.15 86.7506C597.694 87.2086 597.438 87.8299 597.438 88.4777V159.069C597.438 170.605 594.522 173.104 591.83 175.302C591.449 175.627 591.176 176.06 591.047 176.544C590.917 177.029 590.939 177.542 591.107 178.014C591.276 178.486 591.584 178.895 591.991 179.186C592.397 179.477 592.883 179.637 593.382 179.643C593.958 179.643 594.515 179.437 594.952 179.06C598.074 176.467 602.336 172.879 602.242 159.05V88.4777C602.243 87.8396 601.994 87.2267 601.551 86.7702C601.107 86.3137 600.503 86.0499 599.868 86.0352Z"
                fill="#FFC886"
              />
              <path
                d="M335.6 59.6185C332.479 62.2302 328.123 65.8189 328.291 79.6478V139.961C328.291 157.679 319.337 176.637 294.213 176.637C269.09 176.637 260.117 157.679 260.117 139.961V79.6666C260.117 65.8189 255.948 62.2302 252.826 59.6185C252.588 59.3708 252.299 59.1764 251.981 59.0479C251.662 58.9193 251.321 58.8595 250.978 58.8723C250.634 58.8851 250.298 58.9703 249.99 59.1222C249.682 59.2742 249.409 59.4896 249.189 59.7544C248.969 60.0192 248.807 60.3276 248.713 60.6594C248.619 60.9913 248.596 61.3393 248.644 61.6808C248.693 62.0224 248.813 62.3498 248.995 62.6419C249.178 62.934 249.42 63.1842 249.705 63.3764C252.34 65.5747 255.313 68.0736 255.313 79.629V139.942C255.313 170.587 275.445 181.504 294.27 181.504C313.094 181.504 333.208 170.587 333.208 139.942V79.629C333.208 68.0924 336.105 65.5935 338.816 63.3951C339.293 62.9759 339.588 62.3859 339.639 61.7509C339.689 61.1159 339.491 60.4862 339.086 59.996C338.682 59.5058 338.102 59.194 337.472 59.127C336.842 59.06 336.21 59.2431 335.713 59.6373L335.6 59.6185Z"
                fill="#FFC886"
              />
              <path
                d="M768.799 168.539C784.128 168.539 794.821 156.458 794.821 139.153V61.5163C794.821 60.8685 794.564 60.2472 794.109 59.7891C793.653 59.3311 793.035 59.0737 792.39 59.0737C791.746 59.0737 791.128 59.3311 790.672 59.7891C790.216 60.2472 789.96 60.8685 789.96 61.5163V139.153C789.96 153.809 781.455 163.579 768.799 163.579C756.144 163.579 747.732 153.809 747.732 139.153V61.5163C747.732 60.8685 747.476 60.2472 747.02 59.7891C746.565 59.3311 745.947 59.0737 745.302 59.0737C744.658 59.0737 744.039 59.3311 743.584 59.7891C743.128 60.2472 742.872 60.8685 742.872 61.5163V139.153C742.76 156.458 753.471 168.539 768.799 168.539Z"
                fill="#FFC886"
              />
              <path
                d="M810.187 59.6185C807.065 62.2302 802.709 65.8189 802.877 79.6478V139.961C802.877 157.679 793.923 176.637 768.8 176.637C743.676 176.637 734.647 157.679 734.647 139.961V79.6666C734.74 65.8002 730.478 62.2302 727.357 59.6185C727.118 59.3708 726.83 59.1764 726.511 59.0479C726.193 58.9193 725.851 58.8595 725.508 58.8723C725.165 58.8851 724.828 58.9703 724.52 59.1222C724.212 59.2742 723.939 59.4896 723.719 59.7544C723.499 60.0192 723.337 60.3276 723.243 60.6594C723.149 60.9913 723.126 61.3393 723.174 61.6808C723.223 62.0224 723.343 62.3498 723.525 62.6419C723.708 62.934 723.95 63.1842 724.235 63.3764C726.871 65.5747 729.843 68.0736 729.843 79.629V139.942C729.843 170.587 749.957 181.504 768.8 181.504C787.642 181.504 807.738 170.606 807.738 139.961V79.629C807.644 68.0924 810.635 65.5935 813.346 63.3951C813.823 62.9759 814.118 62.3859 814.169 61.7509C814.219 61.1159 814.021 60.4862 813.616 59.996C813.212 59.5058 812.632 59.194 812.002 59.127C811.372 59.06 810.74 59.2431 810.243 59.6373L810.187 59.6185Z"
                fill="#FFC886"
              />
              <path
                d="M60.5669 63.394C60.8217 63.1923 61.0337 62.9413 61.1905 62.6559C61.3473 62.3705 61.4456 62.0565 61.4797 61.7322C61.5139 61.408 61.4831 61.0803 61.3892 60.7682C61.2953 60.4562 61.1402 60.1662 60.933 59.9153C60.7259 59.6644 60.4709 59.4578 60.183 59.3076C59.8952 59.1573 59.5803 59.0665 59.257 59.0405C58.9337 59.0145 58.6084 59.0538 58.3005 59.1561C57.9925 59.2584 57.708 59.4216 57.4638 59.6362C54.3421 62.2291 49.9865 65.8178 50.1548 79.6467V159.031C50.1548 172.878 54.3421 176.467 57.4638 179.06C57.8938 179.437 58.4451 179.644 59.0154 179.643C59.5145 179.636 59.9998 179.477 60.4063 179.186C60.8128 178.895 61.1211 178.486 61.2898 178.014C61.4586 177.542 61.4798 177.029 61.3506 176.544C61.2214 176.06 60.948 175.626 60.5669 175.302C57.9312 173.104 54.9589 170.605 54.9589 159.05V79.6279C54.9402 68.0913 57.9499 65.5924 60.5669 63.394Z"
                fill="#FFC886"
              />
              <path
                d="M538.741 59.6368C538.496 59.4307 538.212 59.2753 537.907 59.1794C537.602 59.0835 537.281 59.0489 536.962 59.0777C536.644 59.1065 536.334 59.1981 536.051 59.3472C535.767 59.4963 535.516 59.7 535.311 59.9468C535.106 60.1935 534.952 60.4784 534.856 60.7852C534.761 61.092 534.726 61.4147 534.755 61.7349C534.784 62.0551 534.875 62.3664 535.023 62.6512C535.171 62.9359 535.374 63.1886 535.62 63.3946C538.255 65.5929 541.228 68.0919 541.228 79.6472V159.069C541.228 170.606 538.311 173.105 535.62 175.303C535.237 175.628 534.963 176.063 534.835 176.549C534.706 177.036 534.729 177.55 534.9 178.023C535.071 178.496 535.382 178.905 535.792 179.194C536.201 179.484 536.689 179.641 537.19 179.643C537.759 179.641 538.31 179.434 538.741 179.061C541.863 176.468 546.125 172.879 546.032 159.05V79.666C546.125 65.762 541.863 62.2297 538.741 59.6368Z"
                fill="#FFC886"
              />
              <path
                d="M564.744 63.394C564.998 63.1923 565.21 62.9413 565.367 62.6559C565.524 62.3705 565.622 62.0565 565.656 61.7322C565.691 61.408 565.66 61.0803 565.566 60.7682C565.472 60.4562 565.317 60.1662 565.11 59.9153C564.903 59.6644 564.648 59.4578 564.36 59.3076C564.072 59.1573 563.757 59.0665 563.434 59.0405C563.11 59.0145 562.785 59.0538 562.477 59.1561C562.169 59.2584 561.885 59.4216 561.641 59.6362C558.519 62.2291 554.163 65.8178 554.331 79.6466V159.031C554.238 172.878 558.519 176.467 561.641 179.06C562.071 179.437 562.622 179.644 563.192 179.643C563.55 179.646 563.904 179.569 564.227 179.416C564.551 179.263 564.836 179.039 565.061 178.759C565.268 178.512 565.424 178.226 565.52 177.918C565.615 177.61 565.65 177.286 565.62 176.964C565.59 176.643 565.498 176.33 565.347 176.045C565.197 175.76 564.992 175.507 564.744 175.302C562.108 173.104 559.136 170.605 559.136 159.05V79.6279C559.136 68.0913 562.108 65.5924 564.744 63.394Z"
                fill="#FFC886"
              />
            </svg>
            <svg
              viewBox="0 0 943 203"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[80dvw]"
            >
              <path
                d="M294.213 168.539C309.542 168.539 320.234 156.458 320.234 139.153V61.5163C320.234 60.8685 319.978 60.2472 319.522 59.7891C319.067 59.3311 318.449 59.0737 317.804 59.0737C317.16 59.0737 316.541 59.3311 316.086 59.7891C315.63 60.2472 315.374 60.8685 315.374 61.5163V139.153C315.374 153.809 306.869 163.579 294.213 163.579C281.558 163.579 273.052 153.752 273.052 139.153V61.5163C273.052 60.8685 272.796 60.2472 272.341 59.7891C271.885 59.3311 271.267 59.0737 270.622 59.0737C269.978 59.0737 269.36 59.3311 268.904 59.7891C268.448 60.2472 268.192 60.8685 268.192 61.5163V139.153C268.192 156.458 278.885 168.539 294.213 168.539Z"
                fill="#FFC886"
              />
              <path
                d="M159.154 162.602H113.486V61.5163C113.486 60.8685 113.23 60.2472 112.775 59.7891C112.319 59.3311 111.701 59.0737 111.056 59.0737C110.412 59.0737 109.794 59.3311 109.338 59.7891C108.882 60.2472 108.626 60.8685 108.626 61.5163V167.487H159.098C159.743 167.487 160.361 167.23 160.816 166.772C161.272 166.314 161.528 165.692 161.528 165.045C161.528 164.397 161.272 163.776 160.816 163.317C160.361 162.859 159.743 162.602 159.098 162.602H159.154Z"
                fill="#FFC886"
              />
              <path
                d="M153.285 174.739H105.786C105.141 174.739 104.523 174.996 104.067 175.454C103.612 175.912 103.356 176.534 103.356 177.181C103.356 177.829 103.612 178.45 104.067 178.909C104.523 179.367 105.141 179.624 105.786 179.624H153.285C153.93 179.624 154.548 179.367 155.004 178.909C155.459 178.45 155.716 177.829 155.716 177.181C155.716 176.534 155.459 175.912 155.004 175.454C154.548 174.996 153.93 174.739 153.285 174.739Z"
                fill="#FFC886"
              />
              <path
                d="M248.229 174.739H191.981C191.336 174.739 190.718 174.996 190.262 175.454C189.807 175.912 189.551 176.534 189.551 177.181C189.551 177.829 189.807 178.45 190.262 178.909C190.718 179.367 191.336 179.624 191.981 179.624H248.229C248.873 179.624 249.491 179.367 249.947 178.909C250.403 178.45 250.659 177.829 250.659 177.181C250.659 176.534 250.403 175.912 249.947 175.454C249.491 174.996 248.873 174.739 248.229 174.739Z"
                fill="#FFC886"
              />
              <path
                d="M244.115 165.045C244.115 164.397 243.859 163.776 243.404 163.317C242.948 162.859 242.33 162.602 241.685 162.602H199.681V61.5163C199.681 61.1956 199.619 60.8779 199.496 60.5816C199.374 60.2852 199.195 60.016 198.97 59.7891C198.744 59.5623 198.476 59.3824 198.181 59.2597C197.886 59.1369 197.57 59.0737 197.251 59.0737C196.932 59.0737 196.616 59.1369 196.321 59.2597C196.026 59.3824 195.759 59.5623 195.533 59.7891C195.307 60.016 195.128 60.2852 195.006 60.5816C194.884 60.8779 194.821 61.1956 194.821 61.5163V167.487H241.704C242.345 167.482 242.959 167.223 243.41 166.765C243.862 166.308 244.115 165.689 244.115 165.045Z"
                fill="#FFC886"
              />
              <path
                d="M849.91 121.735C855.686 125.305 863.874 127.184 870.304 128.405C873.595 129.119 882.866 131.411 886.287 133.516C889.708 135.62 895.447 140.524 893.765 148.998C893.048 152.663 891.165 155.994 888.4 158.488C885.635 160.983 882.138 162.506 878.436 162.827C871.882 163.528 865.264 162.369 859.332 159.482C854.244 156.831 849.571 153.444 845.461 149.43L844.825 148.829C844.347 148.393 843.716 148.166 843.071 148.198C842.426 148.229 841.82 148.517 841.386 148.998C840.952 149.479 840.726 150.113 840.758 150.762C840.789 151.41 841.076 152.019 841.554 152.455L842.115 152.981C846.559 157.299 851.595 160.956 857.07 163.842C862.571 166.542 868.612 167.949 874.735 167.956C876.173 167.959 877.609 167.865 879.034 167.675C882.866 167.167 895.54 164.405 898.494 149.975C900.905 138.157 892.531 131.599 888.811 129.307C884.007 126.376 871.837 123.67 871.258 123.67C865.182 122.524 857.556 120.796 852.452 117.639C847.726 114.706 844.023 110.37 841.853 105.231C839.684 100.092 839.155 94.4023 840.339 88.948C841.318 83.6643 843.66 78.7315 847.131 74.6429C850.602 70.5543 855.08 67.4531 860.117 65.6494C869.875 61.7788 880.829 62.7183 892.662 68.4678C898.27 71.2298 903.093 72.2256 907.205 71.6056C907.521 71.5562 907.823 71.4449 908.096 71.278C908.369 71.111 908.606 70.8918 908.794 70.6327C908.982 70.3736 909.118 70.0797 909.194 69.7679C909.269 69.4561 909.282 69.1324 909.233 68.8154C909.184 68.4983 909.073 68.1941 908.907 67.92C908.741 67.646 908.523 67.4075 908.265 67.2182C908.008 67.0289 907.715 66.8925 907.405 66.8167C907.095 66.741 906.773 66.7274 906.457 66.7768C903.336 67.2653 899.522 66.3822 894.774 64.0711C878.455 56.1421 865.949 58.0774 858.322 61.1024C852.53 63.1915 847.383 66.7697 843.394 71.4806C839.406 76.1915 836.716 81.8706 835.591 87.9521C834.261 94.3791 834.92 101.061 837.479 107.101C840.039 113.14 844.377 118.247 849.91 121.735Z"
                fill="#FFC886"
              />
              <path
                d="M896.754 118.767C890.847 115.16 882.865 113.356 876.584 112.21C873.051 111.533 863.163 109.316 859.76 107.174C857.316 105.668 855.367 103.471 854.157 100.858C852.947 98.2446 852.529 95.3313 852.956 92.4812C853.409 89.0083 854.826 85.7338 857.045 83.0324C859.263 80.3309 862.193 78.3117 865.499 77.2056C878.585 72.8278 888.922 79.8737 896.493 85.0219L897.67 85.8111C898.206 86.1724 898.862 86.3051 899.495 86.18C900.128 86.0549 900.685 85.6822 901.045 85.1441C901.404 84.6059 901.536 83.9462 901.412 83.3102C901.287 82.6742 900.916 82.1139 900.381 81.7526L899.222 80.9635C891.296 75.571 879.314 67.4353 863.966 72.5647C859.786 73.9634 856.083 76.5169 853.279 79.9328C850.476 83.3488 848.686 87.4892 848.115 91.88C847.586 95.6493 848.158 99.4918 849.761 102.941C851.363 106.39 853.928 109.296 857.143 111.308C861.854 114.314 874.21 116.738 875.612 116.945C881.501 118.016 888.922 119.669 894.137 122.863C903.764 128.744 908.232 139.229 906.101 150.897C904.531 159.559 900.605 166.06 894.437 170.156C883.893 177.22 872.191 177.972 858.658 172.523C855.422 171.124 852.27 169.537 849.217 167.769C846.638 166.341 844.189 165.007 841.89 164.012C839.189 162.757 836.247 162.116 833.272 162.133C832.628 162.133 832.009 162.39 831.554 162.848C831.098 163.306 830.842 163.927 830.842 164.575C830.842 165.223 831.098 165.844 831.554 166.302C832.009 166.761 832.628 167.018 833.272 167.018C835.545 167 837.796 167.474 839.871 168.408C842.002 169.348 844.357 170.644 846.843 172.016C850.063 173.88 853.39 175.549 856.807 177.014C862.687 179.503 868.99 180.83 875.369 180.922C883.171 181.003 890.812 178.697 897.278 174.308C904.587 169.442 909.204 161.888 911.036 151.761C913.466 138.101 908.157 125.738 896.754 118.767Z"
                fill="#FFC886"
              />
              <path
                d="M383.213 95.3545C382.569 95.3545 381.951 95.6118 381.495 96.0699C381.039 96.528 380.783 97.1493 380.783 97.7971V177.181C380.783 177.829 381.039 178.45 381.495 178.908C381.951 179.367 382.569 179.624 383.213 179.624C383.858 179.624 384.476 179.367 384.932 178.908C385.387 178.45 385.643 177.829 385.643 177.181V97.7971C385.643 97.1493 385.387 96.528 384.932 96.0699C384.476 95.6118 383.858 95.3545 383.213 95.3545Z"
                fill="#FFC886"
              />
              <path
                d="M502.139 74.649C501.93 74.0361 501.488 73.5316 500.91 73.2462C500.331 72.9608 499.664 72.9179 499.054 73.1271C495.762 74.1657 492.586 75.5447 489.577 77.2419C466.883 90.7889 448.171 118.897 436.88 156.401C436.076 159.106 435.16 162.244 434.301 165.607C433.422 162.244 432.431 159.106 431.702 156.401C420.486 118.822 401.699 90.7889 379.006 77.2419C375.998 75.5414 372.822 74.1622 369.528 73.1271C369.222 73.0043 368.893 72.9455 368.563 72.9545C368.233 72.9635 367.909 73.0399 367.609 73.1792C367.309 73.3184 367.041 73.5176 366.82 73.7645C366.6 74.0114 366.432 74.3009 366.326 74.6153C366.221 74.9297 366.18 75.2624 366.207 75.5932C366.233 75.9239 366.327 76.2457 366.481 76.539C366.636 76.8322 366.848 77.0908 367.105 77.2989C367.362 77.5069 367.659 77.6602 367.977 77.7492C370.945 78.7046 373.807 79.9638 376.52 81.507C398.166 94.3776 416.112 121.49 427.048 157.829C429.055 164.308 430.647 170.91 431.814 177.595C431.902 178.172 432.193 178.698 432.634 179.078C433.074 179.458 433.636 179.667 434.216 179.667C434.797 179.667 435.359 179.458 435.799 179.078C436.24 178.698 436.531 178.172 436.619 177.595C437.853 170.911 439.495 164.31 441.535 157.829C452.47 121.509 470.416 94.3964 492.007 81.4695C494.719 79.9262 497.582 78.667 500.55 77.7116C501.164 77.5172 501.677 77.0854 501.975 76.511C502.273 75.9367 502.332 75.2669 502.139 74.649Z"
                fill="#FFC886"
              />
              <path
                d="M368.127 63.8824C373.402 65.3078 378.472 67.4107 383.212 70.1392C403.046 82.1831 419.87 103.697 431.815 132.35C432.017 132.83 432.355 133.239 432.788 133.526C433.22 133.814 433.727 133.967 434.245 133.966C434.763 133.964 435.268 133.807 435.698 133.516C436.127 133.225 436.461 132.813 436.657 132.331C448.658 103.697 465.482 82.1831 485.259 70.1204C490.04 67.3991 495.147 65.3028 500.457 63.8824C500.766 63.8035 501.057 63.6641 501.313 63.4721C501.568 63.2802 501.784 63.0396 501.947 62.7639C502.11 62.4882 502.218 62.1829 502.264 61.8655C502.309 61.548 502.292 61.2246 502.214 60.9137C502.146 60.5939 502.014 60.2911 501.828 60.0228C501.642 59.7545 501.404 59.5262 501.129 59.3513C500.854 59.1764 500.548 59.0583 500.227 59.0039C499.906 58.9496 499.577 58.9601 499.26 59.0348C493.516 60.577 487.998 62.8701 482.848 65.8553C463.257 77.7676 446.545 98.3605 434.245 125.605C422.001 98.3605 405.27 77.7676 385.642 65.8553C380.492 62.8701 374.974 60.577 369.23 59.0348C368.919 58.9559 368.596 58.9392 368.279 58.9857C367.962 59.0322 367.658 59.141 367.382 59.3058C367.107 59.4707 366.867 59.6884 366.676 59.9466C366.484 60.2048 366.345 60.4983 366.267 60.8104C366.188 61.1225 366.172 61.4472 366.218 61.7657C366.264 62.0843 366.372 62.3906 366.537 62.6672C366.701 62.9437 366.917 63.1851 367.174 63.3775C367.431 63.5699 367.723 63.7095 368.033 63.7885L368.127 63.8824Z"
                fill="#FFC886"
              />
              <path
                d="M431.815 74.6113V95.0351C431.868 95.6483 432.149 96.2192 432.6 96.6351C433.052 97.0509 433.642 97.2816 434.254 97.2816C434.867 97.2816 435.457 97.0509 435.909 96.6351C436.36 96.2192 436.64 95.6483 436.694 95.0351V74.6113C436.64 73.998 436.36 73.4272 435.909 73.0113C435.457 72.5954 434.867 72.3647 434.254 72.3647C433.642 72.3647 433.052 72.5954 432.6 73.0113C432.149 73.4272 431.868 73.998 431.815 74.6113Z"
                fill="#FFC886"
              />
              <path
                d="M434.302 46.7096C434.621 46.7096 434.937 46.6464 435.232 46.5237C435.527 46.4009 435.795 46.221 436.021 45.9942C436.246 45.7674 436.425 45.4981 436.547 45.2018C436.669 44.9054 436.732 44.5878 436.732 44.267V23.8432C436.679 23.23 436.399 22.6591 435.947 22.2432C435.496 21.8274 434.905 21.5967 434.293 21.5967C433.68 21.5967 433.09 21.8274 432.639 22.2432C432.187 22.6591 431.907 23.23 431.853 23.8432V44.267C431.858 44.9166 432.118 45.5378 432.577 45.9954C433.036 46.4529 433.656 46.7096 434.302 46.7096Z"
                fill="#FFC886"
              />
              <path
                d="M447.648 59.6743C447.648 60.3271 447.906 60.9532 448.366 61.4148C448.825 61.8763 449.448 62.1357 450.097 62.1357H464.173C464.818 62.1357 465.436 61.8783 465.892 61.4203C466.347 60.9622 466.603 60.3409 466.603 59.6931C466.603 59.0453 466.347 58.424 465.892 57.9659C465.436 57.5078 464.818 57.2505 464.173 57.2505H450.097C449.454 57.2504 448.837 57.5046 448.379 57.9581C447.921 58.4116 447.658 59.028 447.648 59.6743Z"
                fill="#FFC886"
              />
              <path
                d="M404.43 62.1353H418.487C419.132 62.1353 419.75 61.878 420.206 61.4199C420.661 60.9618 420.917 60.3405 420.917 59.6927C420.917 59.0449 420.661 58.4236 420.206 57.9655C419.75 57.5075 419.132 57.2501 418.487 57.2501H404.43C404.092 57.2203 403.751 57.2616 403.429 57.3713C403.108 57.481 402.812 57.6567 402.562 57.8873C402.311 58.1179 402.112 58.3983 401.975 58.7107C401.838 59.0232 401.767 59.3608 401.767 59.7021C401.767 60.0435 401.838 60.3811 401.975 60.6935C402.112 61.0059 402.311 61.2863 402.562 61.5169C402.812 61.7475 403.108 61.9233 403.429 62.0329C403.751 62.1426 404.092 62.1839 404.43 62.1541V62.1353Z"
                fill="#FFC886"
              />
              <path
                d="M430.75 59.6559L419.01 70.9294C418.658 71.2598 418.411 71.6881 418.302 72.1599C418.192 72.6316 418.224 73.1254 418.395 73.5785C418.565 74.0317 418.866 74.4237 419.258 74.7047C419.651 74.9858 420.117 75.1432 420.599 75.1569C421.226 75.1599 421.83 74.9172 422.282 74.4805L434.227 63.1131L446.153 74.4805C446.606 74.9156 447.209 75.1579 447.835 75.1569C448.323 75.1579 448.8 75.0111 449.204 74.7357C449.608 74.4603 449.92 74.069 450.1 73.6128C450.279 73.1566 450.318 72.6566 450.211 72.178C450.104 71.6994 449.856 71.2643 449.499 70.9294L437.76 59.6559L449.462 48.5139C449.694 48.2931 449.88 48.0285 450.01 47.7352C450.141 47.442 450.212 47.1258 450.221 46.8048C450.229 46.4837 450.175 46.1641 450.061 45.8642C449.947 45.5643 449.775 45.2899 449.555 45.0567C449.335 44.8235 449.072 44.6361 448.78 44.5052C448.489 44.3743 448.174 44.3024 447.855 44.2937C447.535 44.285 447.217 44.3396 446.919 44.4544C446.62 44.5692 446.348 44.7419 446.116 44.9628L434.227 56.2362L422.319 44.9628C422.088 44.7393 421.816 44.5642 421.517 44.4477C421.218 44.3313 420.9 44.2758 420.58 44.2846C420.259 44.2933 419.944 44.3661 419.652 44.4987C419.36 44.6313 419.098 44.8209 418.879 45.0567C418.427 45.5173 418.174 46.1383 418.174 46.7853C418.174 47.4323 418.427 48.0533 418.879 48.5139L430.75 59.6559Z"
                fill="#FFC886"
              />
              <path
                d="M694.363 59.0537C693.717 59.0587 693.099 59.3202 692.644 59.7812C692.188 60.2423 691.933 60.8655 691.933 61.5151V177.181C691.933 177.829 692.189 178.45 692.645 178.908C693.1 179.366 693.719 179.624 694.363 179.624C695.008 179.624 695.626 179.366 696.081 178.908C696.537 178.45 696.793 177.829 696.793 177.181V61.5151C696.793 60.8655 696.538 60.2423 696.082 59.7812C695.627 59.3202 695.009 59.0587 694.363 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M653.33 117.846C644.916 106.683 635.08 96.6765 624.075 88.0844C616.593 82.1177 608.359 77.1709 599.587 73.3725C599.286 73.2356 598.961 73.1619 598.631 73.1557C598.3 73.1495 597.972 73.2111 597.667 73.3366C597.361 73.4621 597.084 73.6489 596.852 73.8856C596.621 74.1224 596.44 74.404 596.32 74.7135C596.201 75.0229 596.145 75.3536 596.157 75.6853C596.169 76.0171 596.248 76.3429 596.389 76.6429C596.53 76.943 596.731 77.2109 596.979 77.4304C597.226 77.6499 597.516 77.8163 597.83 77.9195C606.184 81.5876 614.021 86.3445 621.141 92.0677C631.795 100.401 641.322 110.097 649.48 120.909C658.628 132.552 665.595 145.769 670.042 159.915C671.854 165.761 673.352 171.701 674.529 177.709C674.633 178.269 674.93 178.775 675.367 179.139C675.804 179.503 676.354 179.701 676.921 179.7C677.07 179.718 677.221 179.718 677.37 179.7C677.991 179.565 678.535 179.19 678.885 178.656C679.234 178.122 679.361 177.472 679.239 176.844C678.03 170.665 676.489 164.556 674.622 158.544C670.038 143.793 662.823 130.003 653.33 117.846Z"
                fill="#FFC886"
              />
              <path
                d="M681.203 59.0537C680.556 59.0587 679.938 59.3202 679.483 59.7812C679.028 60.2423 678.773 60.8655 678.773 61.5151V133.496C672.648 121.149 664.778 109.757 655.406 99.6759C645.408 88.5808 633.993 78.864 621.459 70.7781C614.391 66.446 607.055 62.5738 599.494 59.1852C599.191 59.0816 598.871 59.039 598.551 59.0599C598.231 59.0807 597.919 59.1647 597.632 59.3069C597.345 59.4491 597.088 59.6467 596.877 59.8886C596.665 60.1305 596.504 60.4118 596.401 60.7165C596.297 61.0213 596.255 61.3434 596.276 61.6646C596.297 61.9858 596.38 62.2997 596.522 62.5885C596.663 62.8773 596.86 63.1352 597.1 63.3475C597.341 63.5599 597.621 63.7225 597.924 63.8262C605.122 67.0949 612.114 70.8033 618.861 74.9306C631.045 82.8247 642.138 92.3011 651.854 103.114C663.382 115.562 672.561 130.015 678.941 145.766C679.126 146.216 679.439 146.601 679.842 146.872C680.245 147.143 680.718 147.287 681.203 147.288C681.358 147.306 681.515 147.306 681.67 147.288C682.204 147.16 682.679 146.855 683.019 146.422C683.358 145.989 683.542 145.453 683.539 144.901V61.5151C683.54 60.8814 683.297 60.2718 682.862 59.8133C682.427 59.3548 681.833 59.0827 681.203 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M34.5631 59.6368C34.0673 59.2207 33.4274 59.0196 32.7841 59.0777C32.1408 59.1359 31.5469 59.4485 31.1329 59.9468C30.7189 60.4451 30.5188 61.0883 30.5767 61.7349C30.6345 62.3815 30.9456 62.9785 31.4413 63.3946C34.0771 65.5929 37.0493 68.0919 37.0493 79.6472V159.069C37.0493 170.606 34.1332 173.105 31.4413 175.303C31.0603 175.627 30.7868 176.06 30.6576 176.545C30.5284 177.029 30.5496 177.542 30.7184 178.014C30.8872 178.486 31.1955 178.895 31.602 179.187C32.0085 179.478 32.4938 179.637 32.9929 179.643C33.5687 179.644 34.1257 179.437 34.5631 179.061C37.6662 176.468 42.0404 172.879 41.8535 159.05V79.666C41.947 65.762 37.6662 62.2297 34.5631 59.6368Z"
                fill="#FFC886"
              />
              <path
                d="M97.916 59.0537C97.2698 59.0587 96.6517 59.3202 96.1965 59.7812C95.7413 60.2423 95.4859 60.8655 95.4859 61.5151V159.068C95.4859 170.605 92.5697 173.104 89.8779 175.302C89.4969 175.626 89.2234 176.06 89.0942 176.544C88.965 177.029 88.9862 177.542 89.155 178.014C89.3238 178.486 89.6321 178.895 90.0386 179.186C90.4451 179.477 90.9304 179.636 91.4294 179.643C92.0003 179.647 92.5528 179.44 92.981 179.06C96.1028 176.467 100.458 172.878 100.29 159.05V61.5151C100.29 60.8751 100.043 60.2602 99.5995 59.8007C99.1563 59.3412 98.5524 59.0733 97.916 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M184.111 59.0537C183.465 59.0587 182.847 59.3202 182.391 59.7812C181.936 60.2423 181.681 60.8655 181.681 61.5151V159.068C181.681 170.605 178.765 173.104 176.073 175.302C175.692 175.626 175.418 176.06 175.289 176.544C175.16 177.029 175.181 177.542 175.35 178.014C175.519 178.486 175.827 178.895 176.234 179.186C176.64 179.477 177.125 179.636 177.624 179.643C178.195 179.644 178.746 179.437 179.176 179.06C182.298 176.467 186.653 172.878 186.485 159.05V61.5151C186.485 60.8751 186.238 60.2602 185.794 59.8007C185.351 59.3412 184.747 59.0733 184.111 59.0537Z"
                fill="#FFC886"
              />
              <path
                d="M370.128 86.0352C369.484 86.0352 368.866 86.2925 368.41 86.7506C367.954 87.2086 367.698 87.8299 367.698 88.4777V159.069C367.698 170.605 364.782 173.104 362.09 175.302C361.709 175.627 361.436 176.06 361.306 176.544C361.177 177.029 361.198 177.542 361.367 178.014C361.536 178.486 361.844 178.895 362.251 179.186C362.657 179.477 363.143 179.637 363.642 179.643C364.212 179.644 364.763 179.437 365.193 179.06C368.315 176.467 372.671 172.879 372.502 159.05V88.4777C372.503 87.8396 372.254 87.2267 371.811 86.7702C371.367 86.3137 370.763 86.0499 370.128 86.0352Z"
                fill="#FFC886"
              />
              <path
                d="M485.352 95.3545C484.709 95.3594 484.094 95.6183 483.639 96.0753C483.185 96.5324 482.927 97.1508 482.922 97.7971V177.181C482.893 177.521 482.934 177.864 483.043 178.187C483.152 178.51 483.327 178.807 483.556 179.059C483.786 179.311 484.065 179.512 484.375 179.649C484.686 179.787 485.022 179.858 485.362 179.858C485.701 179.858 486.037 179.787 486.348 179.649C486.659 179.512 486.938 179.311 487.167 179.059C487.397 178.807 487.571 178.51 487.681 178.187C487.79 177.864 487.831 177.521 487.801 177.181V97.7971C487.796 97.1475 487.536 96.5263 487.077 96.0688C486.619 95.6112 485.999 95.3545 485.352 95.3545Z"
                fill="#FFC886"
              />
              <path
                d="M500.981 159.051V88.4785C500.928 87.8652 500.648 87.2944 500.196 86.8785C499.745 86.4626 499.154 86.2319 498.542 86.2319C497.93 86.2319 497.339 86.4626 496.888 86.8785C496.436 87.2944 496.156 87.8652 496.103 88.4785V159.032C496.009 172.879 500.29 176.468 503.412 179.061C503.842 179.438 504.393 179.645 504.963 179.643C505.469 179.653 505.965 179.505 506.383 179.219C506.801 178.934 507.122 178.526 507.3 178.05C507.478 177.575 507.505 177.055 507.378 176.563C507.251 176.072 506.975 175.631 506.589 175.303C503.86 173.105 500.869 170.606 500.981 159.051Z"
                fill="#FFC886"
              />
              <path
                d="M613.029 95.3545C612.384 95.3545 611.766 95.6118 611.31 96.0699C610.854 96.528 610.599 97.1493 610.599 97.7971V177.181C610.599 177.829 610.854 178.45 611.31 178.908C611.766 179.367 612.384 179.624 613.029 179.624C613.673 179.624 614.291 179.367 614.747 178.908C615.203 178.45 615.459 177.829 615.459 177.181V97.7971C615.459 97.1493 615.203 96.528 614.747 96.0699C614.291 95.6118 613.673 95.3545 613.029 95.3545Z"
                fill="#FFC886"
              />
              <path
                d="M599.868 86.0352C599.224 86.0352 598.606 86.2925 598.15 86.7506C597.694 87.2086 597.438 87.8299 597.438 88.4777V159.069C597.438 170.605 594.522 173.104 591.83 175.302C591.449 175.627 591.176 176.06 591.047 176.544C590.917 177.029 590.939 177.542 591.107 178.014C591.276 178.486 591.584 178.895 591.991 179.186C592.397 179.477 592.883 179.637 593.382 179.643C593.958 179.643 594.515 179.437 594.952 179.06C598.074 176.467 602.336 172.879 602.242 159.05V88.4777C602.243 87.8396 601.994 87.2267 601.551 86.7702C601.107 86.3137 600.503 86.0499 599.868 86.0352Z"
                fill="#FFC886"
              />
              <path
                d="M335.6 59.6185C332.479 62.2302 328.123 65.8189 328.291 79.6478V139.961C328.291 157.679 319.337 176.637 294.213 176.637C269.09 176.637 260.117 157.679 260.117 139.961V79.6666C260.117 65.8189 255.948 62.2302 252.826 59.6185C252.588 59.3708 252.299 59.1764 251.981 59.0479C251.662 58.9193 251.321 58.8595 250.978 58.8723C250.634 58.8851 250.298 58.9703 249.99 59.1222C249.682 59.2742 249.409 59.4896 249.189 59.7544C248.969 60.0192 248.807 60.3276 248.713 60.6594C248.619 60.9913 248.596 61.3393 248.644 61.6808C248.693 62.0224 248.813 62.3498 248.995 62.6419C249.178 62.934 249.42 63.1842 249.705 63.3764C252.34 65.5747 255.313 68.0736 255.313 79.629V139.942C255.313 170.587 275.445 181.504 294.27 181.504C313.094 181.504 333.208 170.587 333.208 139.942V79.629C333.208 68.0924 336.105 65.5935 338.816 63.3951C339.293 62.9759 339.588 62.3859 339.639 61.7509C339.689 61.1159 339.491 60.4862 339.086 59.996C338.682 59.5058 338.102 59.194 337.472 59.127C336.842 59.06 336.21 59.2431 335.713 59.6373L335.6 59.6185Z"
                fill="#FFC886"
              />
              <path
                d="M768.799 168.539C784.128 168.539 794.821 156.458 794.821 139.153V61.5163C794.821 60.8685 794.564 60.2472 794.109 59.7891C793.653 59.3311 793.035 59.0737 792.39 59.0737C791.746 59.0737 791.128 59.3311 790.672 59.7891C790.216 60.2472 789.96 60.8685 789.96 61.5163V139.153C789.96 153.809 781.455 163.579 768.799 163.579C756.144 163.579 747.732 153.809 747.732 139.153V61.5163C747.732 60.8685 747.476 60.2472 747.02 59.7891C746.565 59.3311 745.947 59.0737 745.302 59.0737C744.658 59.0737 744.039 59.3311 743.584 59.7891C743.128 60.2472 742.872 60.8685 742.872 61.5163V139.153C742.76 156.458 753.471 168.539 768.799 168.539Z"
                fill="#FFC886"
              />
              <path
                d="M810.187 59.6185C807.065 62.2302 802.709 65.8189 802.877 79.6478V139.961C802.877 157.679 793.923 176.637 768.8 176.637C743.676 176.637 734.647 157.679 734.647 139.961V79.6666C734.74 65.8002 730.478 62.2302 727.357 59.6185C727.118 59.3708 726.83 59.1764 726.511 59.0479C726.193 58.9193 725.851 58.8595 725.508 58.8723C725.165 58.8851 724.828 58.9703 724.52 59.1222C724.212 59.2742 723.939 59.4896 723.719 59.7544C723.499 60.0192 723.337 60.3276 723.243 60.6594C723.149 60.9913 723.126 61.3393 723.174 61.6808C723.223 62.0224 723.343 62.3498 723.525 62.6419C723.708 62.934 723.95 63.1842 724.235 63.3764C726.871 65.5747 729.843 68.0736 729.843 79.629V139.942C729.843 170.587 749.957 181.504 768.8 181.504C787.642 181.504 807.738 170.606 807.738 139.961V79.629C807.644 68.0924 810.635 65.5935 813.346 63.3951C813.823 62.9759 814.118 62.3859 814.169 61.7509C814.219 61.1159 814.021 60.4862 813.616 59.996C813.212 59.5058 812.632 59.194 812.002 59.127C811.372 59.06 810.74 59.2431 810.243 59.6373L810.187 59.6185Z"
                fill="#FFC886"
              />
              <path
                d="M60.5669 63.394C60.8217 63.1923 61.0337 62.9413 61.1905 62.6559C61.3473 62.3705 61.4456 62.0565 61.4797 61.7322C61.5139 61.408 61.4831 61.0803 61.3892 60.7682C61.2953 60.4562 61.1402 60.1662 60.933 59.9153C60.7259 59.6644 60.4709 59.4578 60.183 59.3076C59.8952 59.1573 59.5803 59.0665 59.257 59.0405C58.9337 59.0145 58.6084 59.0538 58.3005 59.1561C57.9925 59.2584 57.708 59.4216 57.4638 59.6362C54.3421 62.2291 49.9865 65.8178 50.1548 79.6467V159.031C50.1548 172.878 54.3421 176.467 57.4638 179.06C57.8938 179.437 58.4451 179.644 59.0154 179.643C59.5145 179.636 59.9998 179.477 60.4063 179.186C60.8128 178.895 61.1211 178.486 61.2898 178.014C61.4586 177.542 61.4798 177.029 61.3506 176.544C61.2214 176.06 60.948 175.626 60.5669 175.302C57.9312 173.104 54.9589 170.605 54.9589 159.05V79.6279C54.9402 68.0913 57.9499 65.5924 60.5669 63.394Z"
                fill="#FFC886"
              />
              <path
                d="M538.741 59.6368C538.496 59.4307 538.212 59.2753 537.907 59.1794C537.602 59.0835 537.281 59.0489 536.962 59.0777C536.644 59.1065 536.334 59.1981 536.051 59.3472C535.767 59.4963 535.516 59.7 535.311 59.9468C535.106 60.1935 534.952 60.4784 534.856 60.7852C534.761 61.092 534.726 61.4147 534.755 61.7349C534.784 62.0551 534.875 62.3664 535.023 62.6512C535.171 62.9359 535.374 63.1886 535.62 63.3946C538.255 65.5929 541.228 68.0919 541.228 79.6472V159.069C541.228 170.606 538.311 173.105 535.62 175.303C535.237 175.628 534.963 176.063 534.835 176.549C534.706 177.036 534.729 177.55 534.9 178.023C535.071 178.496 535.382 178.905 535.792 179.194C536.201 179.484 536.689 179.641 537.19 179.643C537.759 179.641 538.31 179.434 538.741 179.061C541.863 176.468 546.125 172.879 546.032 159.05V79.666C546.125 65.762 541.863 62.2297 538.741 59.6368Z"
                fill="#FFC886"
              />
              <path
                d="M564.744 63.394C564.998 63.1923 565.21 62.9413 565.367 62.6559C565.524 62.3705 565.622 62.0565 565.656 61.7322C565.691 61.408 565.66 61.0803 565.566 60.7682C565.472 60.4562 565.317 60.1662 565.11 59.9153C564.903 59.6644 564.648 59.4578 564.36 59.3076C564.072 59.1573 563.757 59.0665 563.434 59.0405C563.11 59.0145 562.785 59.0538 562.477 59.1561C562.169 59.2584 561.885 59.4216 561.641 59.6362C558.519 62.2291 554.163 65.8178 554.331 79.6466V159.031C554.238 172.878 558.519 176.467 561.641 179.06C562.071 179.437 562.622 179.644 563.192 179.643C563.55 179.646 563.904 179.569 564.227 179.416C564.551 179.263 564.836 179.039 565.061 178.759C565.268 178.512 565.424 178.226 565.52 177.918C565.615 177.61 565.65 177.286 565.62 176.964C565.59 176.643 565.498 176.33 565.347 176.045C565.197 175.76 564.992 175.507 564.744 175.302C562.108 173.104 559.136 170.605 559.136 159.05V79.6279C559.136 68.0913 562.108 65.5924 564.744 63.394Z"
                fill="#FFC886"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="bg-cream flex justify-between px-gutter py-6">
        <div className="disclaimers">
          <svg
            width="239"
            height="51"
            viewBox="0 0 239 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.6361 42.3583C78.5246 42.3583 81.2371 39.322 81.2371 34.9728V15.4606C81.2371 15.2978 81.1721 15.1416 81.0565 15.0265C80.9409 14.9114 80.7841 14.8467 80.6206 14.8467C80.4571 14.8467 80.3003 14.9114 80.1847 15.0265C80.0691 15.1416 80.0041 15.2978 80.0041 15.4606V34.9728C80.0041 38.6561 77.8465 41.1117 74.6361 41.1117C71.4257 41.1117 69.2681 38.642 69.2681 34.9728V15.4606C69.2681 15.2978 69.2031 15.1416 69.0875 15.0265C68.9719 14.9114 68.8151 14.8467 68.6516 14.8467C68.4881 14.8467 68.3313 14.9114 68.2157 15.0265C68.1001 15.1416 68.0352 15.2978 68.0352 15.4606V34.9728C68.0352 39.322 70.7476 42.3583 74.6361 42.3583Z"
              fill="#0C2340"
            />
            <path
              d="M40.3744 40.8661H28.7896V15.4606C28.7896 15.2978 28.7246 15.1416 28.609 15.0265C28.4934 14.9114 28.3366 14.8467 28.1731 14.8467C28.0096 14.8467 27.8528 14.9114 27.7372 15.0265C27.6216 15.1416 27.5566 15.2978 27.5566 15.4606V42.0939H40.3602C40.5237 42.0939 40.6805 42.0292 40.7961 41.9141C40.9117 41.799 40.9767 41.6428 40.9767 41.48C40.9767 41.3172 40.9117 41.1611 40.7961 41.0459C40.6805 40.9308 40.5237 40.8661 40.3602 40.8661H40.3744Z"
              fill="#0C2340"
            />
            <path
              d="M38.8848 43.916H26.8352C26.6717 43.916 26.5149 43.9807 26.3993 44.0958C26.2837 44.2109 26.2188 44.3671 26.2188 44.5299C26.2188 44.6927 26.2837 44.8489 26.3993 44.964C26.5149 45.0791 26.6717 45.1438 26.8352 45.1438H38.8848C39.0483 45.1438 39.2051 45.0791 39.3207 44.964C39.4363 44.8489 39.5013 44.6927 39.5013 44.5299C39.5013 44.3671 39.4363 44.2109 39.3207 44.0958C39.2051 43.9807 39.0483 43.916 38.8848 43.916Z"
              fill="#0C2340"
            />
            <path
              d="M62.9693 43.916H48.7005C48.537 43.916 48.3802 43.9807 48.2645 44.0958C48.1489 44.2109 48.084 44.3671 48.084 44.5299C48.084 44.6927 48.1489 44.8489 48.2645 44.964C48.3802 45.0791 48.537 45.1438 48.7005 45.1438H62.9693C63.1328 45.1438 63.2896 45.0791 63.4052 44.964C63.5208 44.8489 63.5858 44.6927 63.5858 44.5299C63.5858 44.3671 63.5208 44.2109 63.4052 44.0958C63.2896 43.9807 63.1328 43.916 62.9693 43.916Z"
              fill="#0C2340"
            />
            <path
              d="M61.9267 41.48C61.9267 41.3172 61.8618 41.1611 61.7461 41.0459C61.6305 40.9308 61.4737 40.8661 61.3102 40.8661H50.6548V15.4606C50.6548 15.38 50.6389 15.3001 50.6079 15.2256C50.5769 15.1512 50.5315 15.0835 50.4743 15.0265C50.417 14.9695 50.349 14.9243 50.2743 14.8934C50.1995 14.8626 50.1193 14.8467 50.0383 14.8467C49.9574 14.8467 49.8772 14.8626 49.8024 14.8934C49.7276 14.9243 49.6597 14.9695 49.6024 15.0265C49.5452 15.0835 49.4998 15.1512 49.4688 15.2256C49.4378 15.3001 49.4219 15.38 49.4219 15.4606V42.0939H61.315C61.4776 42.0927 61.6332 42.0274 61.7478 41.9124C61.8624 41.7974 61.9267 41.642 61.9267 41.48Z"
              fill="#0C2340"
            />
            <path
              d="M215.603 30.5946C217.069 31.4918 219.146 31.9641 220.777 32.271C221.612 32.4505 223.964 33.0266 224.831 33.5555C225.699 34.0843 227.155 35.3168 226.728 37.4466C226.546 38.3676 226.069 39.2047 225.367 39.8317C224.666 40.4586 223.779 40.8414 222.84 40.9221C221.177 41.0983 219.498 40.8071 217.993 40.0816C216.703 39.4151 215.517 38.5639 214.475 37.5552L214.313 37.4041C214.192 37.2945 214.032 37.2374 213.868 37.2454C213.705 37.2533 213.551 37.3257 213.441 37.4466C213.331 37.5674 213.274 37.7269 213.282 37.8898C213.29 38.0528 213.362 38.2059 213.484 38.3155L213.626 38.4477C214.753 39.5329 216.031 40.452 217.42 41.1771C218.815 41.8559 220.348 42.2095 221.901 42.2113C222.266 42.212 222.63 42.1883 222.991 42.1405C223.964 42.013 227.179 41.3188 227.928 37.6921C228.54 34.7218 226.415 33.0738 225.472 32.4977C224.253 31.761 221.166 31.081 221.019 31.081C219.478 30.793 217.543 30.3585 216.248 29.5652C215.049 28.8279 214.11 27.7383 213.56 26.4467C213.009 25.1551 212.875 23.7252 213.175 22.3543C213.424 21.0264 214.018 19.7867 214.898 18.7591C215.779 17.7315 216.915 16.9521 218.192 16.4988C220.668 15.526 223.447 15.7621 226.448 17.2071C227.871 17.9013 229.095 18.1516 230.138 17.9957C230.218 17.9833 230.295 17.9553 230.364 17.9134C230.433 17.8714 230.493 17.8163 230.541 17.7512C230.589 17.6861 230.623 17.6122 230.642 17.5339C230.661 17.4555 230.665 17.3742 230.652 17.2945C230.64 17.2148 230.612 17.1383 230.57 17.0695C230.527 17.0006 230.472 16.9406 230.407 16.8931C230.341 16.8455 230.267 16.8112 230.188 16.7922C230.11 16.7731 230.028 16.7697 229.948 16.7821C229.156 16.9049 228.189 16.6829 226.984 16.1021C222.844 14.1093 219.672 14.5957 217.737 15.356C216.268 15.881 214.962 16.7803 213.95 17.9643C212.939 19.1483 212.256 20.5756 211.971 22.1041C211.633 23.7193 211.801 25.3988 212.45 26.9166C213.099 28.4345 214.2 29.718 215.603 30.5946Z"
              fill="#0C2340"
            />
            <path
              d="M227.486 29.8498C225.988 28.9431 223.963 28.4898 222.369 28.2017C221.473 28.0317 218.965 27.4745 218.102 26.9362C217.482 26.5575 216.987 26.0053 216.68 25.3486C216.373 24.6919 216.267 23.9597 216.375 23.2434C216.491 22.3705 216.85 21.5476 217.413 20.8686C217.975 20.1897 218.719 19.6822 219.557 19.4042C222.877 18.3039 225.499 20.0748 227.42 21.3687L227.719 21.567C227.854 21.6578 228.021 21.6911 228.181 21.6597C228.342 21.6283 228.483 21.5346 228.574 21.3994C228.666 21.2641 228.699 21.0983 228.668 20.9385C228.636 20.7786 228.542 20.6378 228.406 20.547L228.112 20.3487C226.101 18.9934 223.062 16.9487 219.169 18.2378C218.108 18.5894 217.169 19.2311 216.457 20.0896C215.746 20.9482 215.292 21.9887 215.147 23.0923C215.013 24.0396 215.158 25.0053 215.565 25.8721C215.971 26.7389 216.622 27.4695 217.438 27.975C218.633 28.7306 221.767 29.3398 222.123 29.3917C223.617 29.6609 225.499 30.0764 226.822 30.8792C229.264 32.3573 230.398 34.9923 229.857 37.9248C229.459 40.1017 228.463 41.7356 226.898 42.765C224.224 44.5406 221.255 44.7295 217.822 43.36C217.001 43.0084 216.201 42.6095 215.427 42.1653C214.773 41.8064 214.151 41.4712 213.568 41.2209C212.883 40.9056 212.137 40.7444 211.382 40.7487C211.219 40.7487 211.062 40.8133 210.946 40.9285C210.831 41.0436 210.766 41.1997 210.766 41.3625C210.766 41.5254 210.831 41.6815 210.946 41.7966C211.062 41.9118 211.219 41.9764 211.382 41.9764C211.959 41.9719 212.53 42.0911 213.056 42.3259C213.597 42.562 214.194 42.8878 214.825 43.2325C215.641 43.701 216.486 44.1204 217.352 44.4887C218.844 45.1142 220.443 45.4477 222.061 45.4709C224.04 45.4914 225.979 44.9116 227.619 43.8087C229.473 42.5856 230.644 40.6873 231.109 38.142C231.726 34.7089 230.379 31.6017 227.486 29.8498Z"
              fill="#0C2340"
            />
            <path
              d="M97.2122 23.9648C97.0487 23.9648 96.8919 24.0295 96.7763 24.1446C96.6607 24.2598 96.5957 24.4159 96.5957 24.5787V44.5301C96.5957 44.6929 96.6607 44.8491 96.7763 44.9642C96.8919 45.0793 97.0487 45.144 97.2122 45.144C97.3757 45.144 97.5325 45.0793 97.6481 44.9642C97.7637 44.8491 97.8286 44.6929 97.8286 44.5301V24.5787C97.8286 24.4159 97.7637 24.2598 97.6481 24.1446C97.5325 24.0295 97.3757 23.9648 97.2122 23.9648Z"
              fill="#0C2340"
            />
            <path
              d="M127.381 18.7611C127.328 18.607 127.216 18.4802 127.07 18.4085C126.923 18.3368 126.754 18.326 126.599 18.3786C125.764 18.6396 124.958 18.9862 124.195 19.4127C118.438 22.8174 113.691 29.8819 110.827 39.3074C110.623 39.9874 110.391 40.7761 110.172 41.6213C109.949 40.7761 109.698 39.9874 109.513 39.3074C106.668 29.863 101.902 22.8174 96.1453 19.4127C95.3824 18.9853 94.5766 18.6387 93.7411 18.3786C93.6633 18.3477 93.58 18.3329 93.4963 18.3352C93.4126 18.3374 93.3302 18.3566 93.2542 18.3916C93.1782 18.4267 93.1101 18.4767 93.0542 18.5388C92.9983 18.6008 92.9556 18.6736 92.9288 18.7526C92.9021 18.8316 92.8917 18.9152 92.8985 18.9984C92.9052 19.0815 92.9289 19.1624 92.9681 19.2361C93.0073 19.3098 93.0611 19.3747 93.1264 19.427C93.1916 19.4793 93.2669 19.5178 93.3475 19.5402C94.1005 19.7803 94.8267 20.0968 95.5147 20.4847C101.006 23.7194 105.558 30.5336 108.332 39.6663C108.842 41.2948 109.246 42.9542 109.542 44.6341C109.564 44.7791 109.638 44.9114 109.749 45.0069C109.861 45.1025 110.004 45.155 110.151 45.155C110.298 45.155 110.441 45.1025 110.553 45.0069C110.664 44.9114 110.738 44.7791 110.76 44.6341C111.073 42.9543 111.49 41.2952 112.008 39.6663C114.782 30.5383 119.334 23.7241 124.811 20.4752C125.499 20.0874 126.225 19.7709 126.978 19.5308C127.134 19.4819 127.264 19.3734 127.34 19.229C127.415 19.0847 127.43 18.9164 127.381 18.7611Z"
              fill="#0C2340"
            />
            <path
              d="M93.3853 16.0555C94.7234 16.4137 96.0097 16.9423 97.2121 17.628C102.243 20.655 106.511 26.0619 109.541 33.2633C109.593 33.3838 109.679 33.4866 109.788 33.5589C109.898 33.6311 110.026 33.6696 110.158 33.6694C110.289 33.6689 110.418 33.6295 110.526 33.5564C110.635 33.4833 110.72 33.3796 110.77 33.2586C113.814 26.0619 118.082 20.655 123.099 17.6233C124.312 16.9393 125.607 16.4125 126.954 16.0555C127.033 16.0357 127.107 16.0006 127.171 15.9524C127.236 15.9042 127.291 15.8437 127.332 15.7744C127.374 15.7051 127.401 15.6284 127.413 15.5486C127.424 15.4688 127.42 15.3875 127.4 15.3094C127.383 15.229 127.349 15.1529 127.302 15.0855C127.255 15.0181 127.195 14.9607 127.125 14.9167C127.055 14.8727 126.977 14.8431 126.896 14.8294C126.815 14.8158 126.731 14.8184 126.651 14.8372C125.194 15.2248 123.794 15.8011 122.487 16.5513C117.518 19.5452 113.278 24.7208 110.158 31.568C107.052 24.7208 102.808 19.5452 97.8286 16.5513C96.522 15.8011 95.1222 15.2248 93.665 14.8372C93.5863 14.8173 93.5043 14.8131 93.4239 14.8248C93.3435 14.8365 93.2662 14.8638 93.1964 14.9053C93.1266 14.9467 93.0657 15.0014 93.0172 15.0663C92.9686 15.1312 92.9334 15.205 92.9134 15.2834C92.8935 15.3619 92.8893 15.4435 92.901 15.5235C92.9128 15.6036 92.9402 15.6806 92.9818 15.7501C93.0234 15.8196 93.0784 15.8802 93.1435 15.9286C93.2087 15.977 93.2828 16.0121 93.3616 16.0319L93.3853 16.0555Z"
              fill="#0C2340"
            />
            <path
              d="M109.541 18.7521V23.8852C109.555 24.0393 109.626 24.1828 109.74 24.2873C109.855 24.3918 110.004 24.4498 110.16 24.4498C110.315 24.4498 110.465 24.3918 110.58 24.2873C110.694 24.1828 110.765 24.0393 110.779 23.8852V18.7521C110.765 18.598 110.694 18.4545 110.58 18.35C110.465 18.2455 110.315 18.1875 110.16 18.1875C110.004 18.1875 109.855 18.2455 109.74 18.35C109.626 18.4545 109.555 18.598 109.541 18.7521Z"
              fill="#0C2340"
            />
            <path
              d="M110.172 11.7393C110.253 11.7393 110.333 11.7234 110.408 11.6926C110.483 11.6617 110.551 11.6165 110.608 11.5595C110.665 11.5025 110.711 11.4348 110.742 11.3603C110.773 11.2858 110.788 11.206 110.788 11.1254V5.99235C110.775 5.83822 110.704 5.69475 110.589 5.59023C110.475 5.48571 110.325 5.42773 110.17 5.42773C110.014 5.42773 109.865 5.48571 109.75 5.59023C109.635 5.69475 109.564 5.83822 109.551 5.99235V11.1254C109.552 11.2887 109.618 11.4448 109.734 11.5598C109.851 11.6748 110.008 11.7393 110.172 11.7393Z"
              fill="#0C2340"
            />
            <path
              d="M113.559 14.9978C113.559 15.1619 113.624 15.3193 113.741 15.4353C113.857 15.5513 114.015 15.6164 114.18 15.6164H117.751C117.914 15.6164 118.071 15.5518 118.186 15.4366C118.302 15.3215 118.367 15.1654 118.367 15.0026C118.367 14.8397 118.302 14.6836 118.186 14.5685C118.071 14.4533 117.914 14.3887 117.751 14.3887H114.18C114.017 14.3887 113.86 14.4525 113.744 14.5665C113.628 14.6805 113.561 14.8354 113.559 14.9978Z"
              fill="#0C2340"
            />
            <path
              d="M102.593 15.6159H106.159C106.323 15.6159 106.48 15.5512 106.595 15.4361C106.711 15.321 106.776 15.1648 106.776 15.002C106.776 14.8392 106.711 14.683 106.595 14.5679C106.48 14.4528 106.323 14.3881 106.159 14.3881H102.593C102.508 14.3806 102.421 14.391 102.34 14.4186C102.258 14.4461 102.183 14.4903 102.12 14.5482C102.056 14.6062 102.005 14.6767 101.971 14.7552C101.936 14.8337 101.918 14.9186 101.918 15.0044C101.918 15.0901 101.936 15.175 101.971 15.2535C102.005 15.332 102.056 15.4025 102.12 15.4605C102.183 15.5184 102.258 15.5626 102.34 15.5902C102.421 15.6177 102.508 15.6281 102.593 15.6206V15.6159Z"
              fill="#0C2340"
            />
            <path
              d="M109.272 14.9933L106.294 17.8267C106.205 17.9097 106.142 18.0174 106.114 18.1359C106.087 18.2545 106.095 18.3786 106.138 18.4925C106.181 18.6064 106.258 18.7049 106.357 18.7755C106.457 18.8462 106.575 18.8857 106.697 18.8892C106.856 18.8899 107.009 18.8289 107.124 18.7192L110.154 15.8622L113.18 18.7192C113.295 18.8285 113.448 18.8894 113.606 18.8892C113.73 18.8894 113.851 18.8525 113.954 18.7833C114.056 18.7141 114.135 18.6157 114.181 18.5011C114.226 18.3864 114.236 18.2608 114.209 18.1405C114.182 18.0202 114.119 17.9108 114.029 17.8267L111.05 14.9933L114.019 12.193C114.078 12.1375 114.125 12.071 114.158 11.9973C114.191 11.9236 114.209 11.8442 114.212 11.7635C114.214 11.6828 114.2 11.6025 114.171 11.5271C114.142 11.4517 114.098 11.3828 114.043 11.3242C113.987 11.2656 113.92 11.2185 113.846 11.1856C113.772 11.1527 113.692 11.1346 113.611 11.1324C113.53 11.1302 113.45 11.1439 113.374 11.1728C113.298 11.2016 113.229 11.245 113.17 11.3005L110.154 14.1339L107.134 11.3005C107.075 11.2444 107.006 11.2004 106.93 11.1711C106.854 11.1418 106.774 11.1279 106.692 11.1301C106.611 11.1323 106.531 11.1506 106.457 11.1839C106.383 11.2172 106.316 11.2649 106.261 11.3242C106.146 11.4399 106.082 11.596 106.082 11.7586C106.082 11.9212 106.146 12.0773 106.261 12.193L109.272 14.9933Z"
              fill="#0C2340"
            />
            <path
              d="M176.144 14.8418C175.98 14.843 175.823 14.9088 175.708 15.0246C175.592 15.1405 175.527 15.2972 175.527 15.4604V44.5304C175.527 44.6932 175.592 44.8494 175.708 44.9645C175.824 45.0796 175.98 45.1443 176.144 45.1443C176.307 45.1443 176.464 45.0796 176.58 44.9645C176.695 44.8494 176.76 44.6932 176.76 44.5304V15.4604C176.76 15.2972 176.695 15.1405 176.58 15.0246C176.465 14.9088 176.308 14.843 176.144 14.8418Z"
              fill="#0C2340"
            />
            <path
              d="M165.735 29.6178C163.6 26.812 161.105 24.2973 158.313 22.1378C156.415 20.6382 154.326 19.395 152.101 18.4403C152.025 18.4059 151.942 18.3874 151.858 18.3858C151.775 18.3843 151.691 18.3998 151.614 18.4313C151.536 18.4628 151.466 18.5098 151.407 18.5693C151.349 18.6288 151.303 18.6996 151.272 18.7774C151.242 18.8551 151.228 18.9382 151.231 19.0216C151.234 19.105 151.254 19.1869 151.29 19.2623C151.326 19.3377 151.376 19.405 151.439 19.4602C151.502 19.5154 151.576 19.5572 151.655 19.5831C153.775 20.505 155.763 21.7005 157.569 23.1389C160.271 25.2334 162.688 27.6702 164.758 30.3876C167.078 33.3137 168.846 36.6355 169.974 40.1909C170.434 41.6601 170.814 43.1529 171.112 44.6628C171.139 44.8037 171.214 44.9309 171.325 45.0223C171.436 45.1137 171.575 45.1636 171.719 45.1634C171.757 45.1679 171.795 45.1679 171.833 45.1634C171.99 45.1293 172.128 45.0351 172.217 44.9009C172.306 44.7667 172.338 44.6032 172.307 44.4456C172 42.8925 171.609 41.3571 171.136 39.8462C169.973 36.1389 168.143 32.6731 165.735 29.6178Z"
              fill="#0C2340"
            />
            <path
              d="M172.805 14.8418C172.641 14.843 172.484 14.9088 172.369 15.0246C172.253 15.1405 172.189 15.2972 172.189 15.4604V33.5512C170.635 30.4479 168.638 27.5849 166.261 25.0512C163.725 22.2628 160.829 19.8206 157.649 17.7885C155.857 16.6997 153.995 15.7265 152.078 14.8749C152.001 14.8488 151.919 14.8381 151.838 14.8433C151.757 14.8486 151.678 14.8697 151.605 14.9054C151.532 14.9412 151.467 14.9908 151.414 15.0516C151.36 15.1124 151.319 15.1831 151.293 15.2597C151.267 15.3363 151.256 15.4173 151.261 15.498C151.266 15.5787 151.288 15.6576 151.323 15.7302C151.359 15.8028 151.409 15.8676 151.47 15.9209C151.531 15.9743 151.602 16.0152 151.679 16.0412C153.505 16.8628 155.279 17.7948 156.99 18.8321C160.081 20.8161 162.895 23.1978 165.36 25.9154C168.284 29.0439 170.613 32.6762 172.231 36.6349C172.278 36.748 172.358 36.8447 172.46 36.9128C172.562 36.9809 172.682 37.0173 172.805 37.0174C172.844 37.022 172.884 37.022 172.924 37.0174C173.059 36.9854 173.18 36.9087 173.266 36.7998C173.352 36.6909 173.398 36.5562 173.398 36.4176V15.4604C173.398 15.3011 173.336 15.1479 173.226 15.0327C173.116 14.9175 172.965 14.8491 172.805 14.8418Z"
              fill="#0C2340"
            />
            <path
              d="M8.76766 14.9887C8.64189 14.8841 8.47955 14.8336 8.31637 14.8482C8.15318 14.8628 8.0025 14.9414 7.89749 15.0666C7.79247 15.1918 7.74172 15.3535 7.75639 15.516C7.77106 15.6785 7.84996 15.8286 7.97573 15.9331C8.64436 16.4856 9.39835 17.1137 9.39835 20.0178V39.9787C9.39835 42.8781 8.65859 43.5062 7.97573 44.0587C7.87907 44.1401 7.8097 44.249 7.77692 44.3708C7.74415 44.4926 7.74953 44.6215 7.79234 44.7401C7.83516 44.8588 7.91336 44.9616 8.01648 45.0348C8.1196 45.1079 8.24271 45.148 8.36932 45.1495C8.51539 45.1496 8.65669 45.0977 8.76766 45.0031C9.55484 44.3515 10.6645 43.4495 10.6171 39.974V20.0226C10.6408 16.5281 9.55484 15.6403 8.76766 14.9887Z"
              fill="#0C2340"
            />
            <path
              d="M24.8391 14.8418C24.6752 14.843 24.5184 14.9088 24.4029 15.0246C24.2875 15.1405 24.2227 15.2972 24.2227 15.4604V39.9782C24.2227 42.8776 23.4829 43.5057 22.8001 44.0582C22.7034 44.1396 22.634 44.2485 22.6013 44.3703C22.5685 44.4921 22.5739 44.621 22.6167 44.7396C22.6595 44.8583 22.7377 44.9611 22.8408 45.0343C22.9439 45.1074 23.067 45.1475 23.1936 45.149C23.3385 45.1502 23.4786 45.098 23.5872 45.0026C24.3792 44.351 25.4841 43.449 25.4414 39.9735V15.4604C25.4415 15.2996 25.3786 15.145 25.2662 15.0295C25.1538 14.914 25.0006 14.8467 24.8391 14.8418Z"
              fill="#0C2340"
            />
            <path
              d="M46.7044 14.8418C46.5404 14.843 46.3837 14.9088 46.2682 15.0246C46.1527 15.1405 46.0879 15.2972 46.0879 15.4604V39.9782C46.0879 42.8776 45.3482 43.5057 44.6653 44.0582C44.5686 44.1396 44.4993 44.2485 44.4665 44.3703C44.4337 44.4921 44.4391 44.621 44.4819 44.7396C44.5247 44.8583 44.6029 44.9611 44.706 45.0343C44.8092 45.1074 44.9323 45.1475 45.0589 45.149C45.2035 45.1493 45.3434 45.0973 45.4525 45.0026C46.2444 44.351 47.3493 43.449 47.3066 39.9735V15.4604C47.3067 15.2996 47.2439 15.145 47.1314 15.0295C47.019 14.914 46.8658 14.8467 46.7044 14.8418Z"
              fill="#0C2340"
            />
            <path
              d="M93.8919 21.623C93.7284 21.623 93.5716 21.6877 93.456 21.8029C93.3404 21.918 93.2754 22.0741 93.2754 22.2369V39.9783C93.2754 42.8778 92.5356 43.5058 91.8528 44.0583C91.7561 44.1398 91.6868 44.2487 91.654 44.3705C91.6212 44.4922 91.6266 44.6211 91.6694 44.7398C91.7122 44.8584 91.7904 44.9612 91.8935 45.0344C91.9967 45.1076 92.1198 45.1476 92.2464 45.1491C92.391 45.1494 92.5309 45.0974 92.64 45.0028C93.4319 44.3511 94.5368 43.4492 94.4941 39.9736V22.2369C94.4942 22.0765 94.4312 21.9225 94.3186 21.8078C94.2061 21.6931 94.0529 21.6267 93.8919 21.623Z"
              fill="#0C2340"
            />
            <path
              d="M123.123 23.9648C122.96 23.9661 122.804 24.0312 122.688 24.146C122.573 24.2609 122.508 24.4163 122.506 24.5787V44.5301C122.499 44.6156 122.509 44.7017 122.537 44.7829C122.565 44.8641 122.609 44.9388 122.667 45.002C122.725 45.0653 122.796 45.1158 122.875 45.1503C122.954 45.1849 123.039 45.2027 123.125 45.2027C123.211 45.2027 123.296 45.1849 123.375 45.1503C123.454 45.1158 123.525 45.0653 123.583 45.002C123.641 44.9388 123.686 44.8641 123.713 44.7829C123.741 44.7017 123.751 44.6156 123.744 44.5301V24.5787C123.743 24.4155 123.677 24.2593 123.56 24.1444C123.444 24.0294 123.287 23.9648 123.123 23.9648Z"
              fill="#0C2340"
            />
            <path
              d="M127.088 39.9741V22.2375C127.074 22.0833 127.003 21.9399 126.888 21.8353C126.774 21.7308 126.624 21.6729 126.469 21.6729C126.313 21.6729 126.164 21.7308 126.049 21.8353C125.935 21.9399 125.864 22.0833 125.85 22.2375V39.9694C125.826 43.4497 126.912 44.3516 127.704 45.0033C127.813 45.0979 127.953 45.15 128.098 45.1497C128.226 45.152 128.352 45.1148 128.458 45.0431C128.564 44.9714 128.645 44.8687 128.69 44.7492C128.736 44.6297 128.743 44.4992 128.71 44.3756C128.678 44.252 128.608 44.1413 128.51 44.0589C127.818 43.5064 127.059 42.8783 127.088 39.9741Z"
              fill="#0C2340"
            />
            <path
              d="M155.511 23.9648C155.348 23.9648 155.191 24.0295 155.075 24.1446C154.959 24.2598 154.895 24.4159 154.895 24.5787V44.5301C154.895 44.6929 154.959 44.8491 155.075 44.9642C155.191 45.0793 155.348 45.144 155.511 45.144C155.675 45.144 155.831 45.0793 155.947 44.9642C156.063 44.8491 156.127 44.6929 156.127 44.5301V24.5787C156.127 24.4159 156.063 24.2598 155.947 24.1446C155.831 24.0295 155.675 23.9648 155.511 23.9648Z"
              fill="#0C2340"
            />
            <path
              d="M152.173 21.623C152.01 21.623 151.853 21.6877 151.737 21.8029C151.622 21.918 151.557 22.0741 151.557 22.2369V39.9783C151.557 42.8778 150.817 43.5058 150.134 44.0583C150.037 44.1398 149.968 44.2487 149.935 44.3705C149.902 44.4922 149.908 44.6211 149.951 44.7398C149.993 44.8584 150.072 44.9612 150.175 45.0344C150.278 45.1076 150.401 45.1476 150.528 45.1492C150.674 45.1493 150.815 45.0974 150.926 45.0028C151.718 44.3511 152.799 43.4492 152.775 39.9736V22.2369C152.775 22.0765 152.712 21.9225 152.6 21.8078C152.487 21.6931 152.334 21.6267 152.173 21.623Z"
              fill="#0C2340"
            />
            <path
              d="M85.1334 14.9839C84.3415 15.6403 83.2366 16.5422 83.2792 20.0178V35.1761C83.2792 39.6291 81.0078 44.3939 74.6345 44.3939C68.2611 44.3939 65.9849 39.6291 65.9849 35.1761V20.0225C65.9849 16.5422 64.9275 15.6403 64.1355 14.9839C64.0749 14.9216 64.0018 14.8728 63.921 14.8404C63.8402 14.8081 63.7535 14.7931 63.6665 14.7963C63.5795 14.7995 63.4941 14.8209 63.416 14.8591C63.3378 14.8973 63.2685 14.9515 63.2127 15.018C63.1569 15.0846 63.1158 15.1621 63.092 15.2455C63.0682 15.3289 63.0623 15.4164 63.0746 15.5022C63.087 15.588 63.1173 15.6703 63.1636 15.7437C63.21 15.8171 63.2713 15.88 63.3436 15.9283C64.0122 16.4808 64.7662 17.1089 64.7662 20.013V35.1714C64.7662 42.8733 69.8734 45.6169 74.6487 45.6169C79.4239 45.6169 84.5264 42.8733 84.5264 35.1714V20.013C84.5264 17.1136 85.2614 16.4855 85.949 15.933C86.0701 15.8277 86.145 15.6794 86.1578 15.5198C86.1707 15.3602 86.1204 15.2019 86.0177 15.0787C85.915 14.9555 85.7681 14.8772 85.6082 14.8603C85.4483 14.8435 85.2881 14.8895 85.1618 14.9886L85.1334 14.9839Z"
              fill="#0C2340"
            />
            <path
              d="M195.027 42.3583C198.915 42.3583 201.628 39.322 201.628 34.9728V15.4606C201.628 15.2978 201.563 15.1416 201.447 15.0265C201.331 14.9114 201.175 14.8467 201.011 14.8467C200.848 14.8467 200.691 14.9114 200.575 15.0265C200.46 15.1416 200.395 15.2978 200.395 15.4606V34.9728C200.395 38.6561 198.237 41.1117 195.027 41.1117C191.816 41.1117 189.682 38.6561 189.682 34.9728V15.4606C189.682 15.2978 189.617 15.1416 189.502 15.0265C189.386 14.9114 189.229 14.8467 189.066 14.8467C188.902 14.8467 188.746 14.9114 188.63 15.0265C188.514 15.1416 188.449 15.2978 188.449 15.4606V34.9728C188.421 39.322 191.138 42.3583 195.027 42.3583Z"
              fill="#0C2340"
            />
            <path
              d="M205.527 14.9839C204.735 15.6403 203.63 16.5422 203.672 20.0178V35.1761C203.672 39.6291 201.401 44.3939 195.028 44.3939C188.654 44.3939 186.364 39.6291 186.364 35.1761V20.0225C186.388 16.5375 185.306 15.6403 184.514 14.9839C184.454 14.9216 184.381 14.8728 184.3 14.8404C184.219 14.8081 184.132 14.7931 184.045 14.7963C183.958 14.7995 183.873 14.8209 183.795 14.8591C183.717 14.8973 183.647 14.9515 183.592 15.018C183.536 15.0846 183.495 15.1621 183.471 15.2455C183.447 15.3289 183.441 15.4164 183.454 15.5022C183.466 15.588 183.496 15.6703 183.543 15.7437C183.589 15.8171 183.65 15.88 183.723 15.9283C184.391 16.4808 185.145 17.1089 185.145 20.013V35.1714C185.145 42.8733 190.248 45.6169 195.028 45.6169C199.808 45.6169 204.905 42.878 204.905 35.1761V20.013C204.882 17.1136 205.64 16.4855 206.328 15.933C206.449 15.8277 206.524 15.6794 206.537 15.5198C206.55 15.3602 206.499 15.2019 206.397 15.0787C206.294 14.9555 206.147 14.8772 205.987 14.8603C205.827 14.8435 205.667 14.8895 205.541 14.9886L205.527 14.9839Z"
              fill="#0C2340"
            />
            <path
              d="M15.3652 15.933C15.4298 15.8823 15.4836 15.8192 15.5234 15.7475C15.5631 15.6758 15.5881 15.5969 15.5967 15.5154C15.6054 15.4339 15.5976 15.3515 15.5738 15.2731C15.55 15.1947 15.5106 15.1218 15.4581 15.0587C15.4055 14.9957 15.3408 14.9437 15.2678 14.906C15.1948 14.8682 15.1149 14.8454 15.0329 14.8389C14.9509 14.8323 14.8684 14.8422 14.7902 14.8679C14.7121 14.8936 14.64 14.9347 14.578 14.9886C13.7861 15.6403 12.6812 16.5422 12.7239 20.0177V39.9691C12.7239 43.4494 13.7861 44.3514 14.578 45.003C14.6871 45.0977 14.8269 45.1497 14.9716 45.1494C15.0982 45.1479 15.2213 45.1078 15.3244 45.0347C15.4276 44.9615 15.5058 44.8587 15.5486 44.74C15.5914 44.6214 15.5968 44.4925 15.564 44.3707C15.5312 44.2489 15.4619 44.14 15.3652 44.0586C14.6966 43.5061 13.9426 42.878 13.9426 39.9739V20.013C13.9378 17.1136 14.7013 16.4855 15.3652 15.933Z"
              fill="#0C2340"
            />
            <path
              d="M136.666 14.9887C136.604 14.9369 136.532 14.8978 136.454 14.8737C136.377 14.8496 136.296 14.8409 136.215 14.8482C136.134 14.8554 136.055 14.8784 135.984 14.9159C135.912 14.9534 135.848 15.0046 135.796 15.0666C135.744 15.1286 135.705 15.2002 135.68 15.2773C135.656 15.3544 135.648 15.4355 135.655 15.516C135.662 15.5965 135.685 15.6747 135.723 15.7463C135.76 15.8179 135.812 15.8813 135.874 15.9331C136.543 16.4856 137.297 17.1137 137.297 20.0178V39.9787C137.297 42.8781 136.557 43.5062 135.874 44.0587C135.777 44.1404 135.708 44.2497 135.675 44.372C135.642 44.4942 135.648 44.6234 135.692 44.7423C135.735 44.8612 135.814 44.9639 135.918 45.0367C136.022 45.1096 136.145 45.1489 136.272 45.1495C136.417 45.1489 136.557 45.097 136.666 45.0031C137.458 44.3515 138.539 43.4495 138.515 39.974V20.0226C138.539 16.5281 137.458 15.6403 136.666 14.9887Z"
              fill="#0C2340"
            />
            <path
              d="M143.262 15.933C143.326 15.8823 143.38 15.8192 143.42 15.7475C143.46 15.6758 143.485 15.5969 143.493 15.5154C143.502 15.4339 143.494 15.3515 143.47 15.2731C143.446 15.1947 143.407 15.1218 143.355 15.0587C143.302 14.9957 143.237 14.9437 143.164 14.906C143.091 14.8682 143.011 14.8454 142.929 14.8389C142.847 14.8323 142.765 14.8422 142.687 14.8679C142.609 14.8936 142.536 14.9347 142.474 14.9886C141.683 15.6403 140.578 16.5422 140.62 20.0177V39.9691C140.597 43.4494 141.683 44.3514 142.474 45.003C142.584 45.0977 142.723 45.1497 142.868 45.1494C142.959 45.1504 143.049 45.1309 143.131 45.0925C143.213 45.0541 143.285 44.9976 143.342 44.9275C143.395 44.8653 143.434 44.7934 143.459 44.716C143.483 44.6386 143.491 44.5571 143.484 44.4763C143.476 44.3955 143.453 44.317 143.415 44.2453C143.377 44.1736 143.325 44.1101 143.262 44.0586C142.593 43.5061 141.839 42.878 141.839 39.9739V20.013C141.839 17.1136 142.593 16.4855 143.262 15.933Z"
              fill="#0C2340"
            />
          </svg>
        </div>

        <div className="logos flex gap-8 justify-end items-center relative z-[1]">
          <svg
            height={40}
            className="handicap"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 483.2226563 551.4306641"
            overflow="visible"
            enableBackground="new 0 0 483.2226563 551.4306641"
            xmlSpace="preserve"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#000000"
              d="M161.9882813,98.1240234  c24.9628906-2.3046875,44.3574219-23.8110352,44.3574219-48.9658203C206.3457031,22.0830078,184.2626953,0,157.1875,0  s-49.1572266,22.0830078-49.1572266,49.1582031c0,8.2568359,2.3037109,16.7055664,6.1445313,23.8105469l17.515625,246.4667969  l180.3964844,0.0488281l73.9912109,173.3652344l97.1445313-38.0976563l-15.0429688-35.8203125l-54.3662109,19.625  l-71.5908203-165.2802734l-167.7294922,1.1269531l-2.3027344-31.2128906l121.4228516,0.0483398v-46.1831055l-126.0546875-0.0493164  L161.9882813,98.1240234z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#000000"
              d="M343.4199219,451.5908203  c-30.4472656,60.1875-94.1748047,99.8398438-162.1503906,99.8398438C81.4296875,551.4306641,0,470.0009766,0,370.1611328  c0-70.1005859,42.4853516-135.2436523,105.8818359-164.1210938l4.1025391,53.5375977  c-37.4970703,23.628418-60.6123047,66.262207-60.6123047,110.9506836c0,72.4267578,59.0712891,131.4970703,131.4970703,131.4970703  c66.2617188,0,122.7646484-50.8515625,130.4697266-116.0869141L343.4199219,451.5908203z"
            />
          </svg>

          <Image
            height="30"
            src="https://images.ctfassets.net/qmuogfuv095q/2IHgfaDMYnz2uT8SMzxy95/2c00ab5f9b62d3e7ae4661ca4e444919/equal-housing-opportunity-logo-1200w.png"
            alt="Equal Housing Opportunity"
            shouldLazyLoad
            imgClassName="h-[40px]"
          />
        </div>
      </div>
    </footer>
  );
}
