"use client";

import { useState, useEffect } from "react";
import Logo from "@/components/icons/Logo";
import Link from "next/link";
import { usePathname } from "next/navigation";
import For from "@/components/For";
import If from "@/components/If";
import Image from "@/components/Image";
import { Button } from "@/components/actions/Button";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { ChevronDown } from "react-feather";

const links = [
  { href: "/communities", label: "Communities", hasDropdown: true },
  { href: "/careers", label: "Careers" },
  { href: "/about-us", label: "About Us" },
  { href: "/donating", label: "Donate" },
  { href: "/contact-us", label: "Contact Us", isButton: true },
];

const Dropdown = ({ onToggle, open }) => {
  const pathname = usePathname();
  return (
    <li className="relative">
      <button
        className={`flex gap-2 transition-all duration-300  items-center ${
          open || pathname.includes("/communities")
            ? "text-orange"
            : "text-white hover:text-orange"
        }`}
        onClick={onToggle}
      >
        Communities{" "}
        <ChevronDown
          className={`${
            open ? "rotate-180" : ""
          } transition-all duration-300 ease-in-out`}
        />
      </button>
    </li>
  );
};

const LinkItem = ({ link }) => {
  const pathname = usePathname();
  return (
    <li>
      <Link
        className={`transition-all duration-300 whitespace-nowrap ${
          pathname.includes(link.href)
            ? "text-orange"
            : "text-white hover:text-orange"
        } ${
          link.isButton ? "px-6 py-3 border-2 broder-white rounded-full" : ""
        }`}
        href={link.href}
      >
        {link.label}
      </Link>
    </li>
  );
};

const MobileLinkItem = ({ link }) => {
  const pathname = usePathname();
  return (
    <li>
      <Link
        className={`transition-all duration-300 text-5xl ${
          pathname.includes(link.href)
            ? "text-orange"
            : "text-white hover:text-orange"
        } `}
        href={link.href}
      >
        {link.label}
      </Link>
    </li>
  );
};

export default function Nav({ communities = [] }) {
  const pathname = usePathname();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isLowerThanNav, setIsLowerThanNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      if (currentScrollPos > 100) {
        setIsLowerThanNav(true);
      } else {
        setIsLowerThanNav(false);
      }

      // setIsHeaderVisible(!isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    setOpen(false);
    setMobileOpen(false);
    if (typeof window === "undefined") return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full z-[49] bg-nav-background nav-effect transition-all duration-300 ${
          isLowerThanNav ? "h-[95px]" : "h-[117px]"
        } ${isHeaderVisible || open ? "" : "transform -translate-y-full"}`}
      ></div>
      <header
        className={`main-nav fixed top-0 left-0 right-0 z-50 transition-all duration-300 w-[100dvw] ease max-w-full ${
          isHeaderVisible || open ? "" : "transform -translate-y-full"
        } ${isLowerThanNav ? "retract" : ""}`}
      >
        <div className="grid nav-layout justify-between items-center py-6 lg:py-8 max-w-full px-gutter relative z-[1]">
          <Link href="/">
            <span className="sr-only">Home</span>
            <Logo
              className="logo"
              color={isLowerThanNav || open ? "black" : "black"}
            />
          </Link>
          <div className="flex justify-end items-center gap-4">
            <ul className="flex gap-10 items-center links">
              <For
                each={links}
                render={(link) => {
                  return link.hasDropdown ? (
                    <Dropdown onToggle={() => setOpen(!open)} open={open} />
                  ) : (
                    <LinkItem link={link} />
                  );
                }}
              />
            </ul>
            <button
              onClick={() => setMobileOpen(!mobileOpen)}
              className="flex flex-col justify-center items-center hamburger"
            >
              <svg
                width={50}
                viewBox="0 0 124 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`transition-all duration-300 ease-in-out ${
                  mobileOpen ? "rotate-45" : ""
                }`}
              >
                <circle cx="62" cy="62" r="62" fill="#FFB154" />
                <path
                  d="M62 79C60.3432 79 59 80.4693 59 82.2839V109.716C59 111.532 60.3447 113 62 113C63.6553 113 65 111.531 65 109.716V82.2839C65 80.4676 63.6553 79 62 79Z"
                  fill="#FEFCED"
                />
                <path
                  d="M62 45C63.6568 45 65 43.5307 65 41.7162V14.2838C65 12.4676 63.6553 11 62 11C60.3447 11 59 12.4693 59 14.2838V41.7162C59 43.5324 60.3447 45 62 45Z"
                  fill="#FEFCED"
                />
                <path
                  d="M79 61.9992C79 63.658 80.4968 65 82.3392 65H101.661C103.505 65 105 63.658 105 61.9992C105 60.3404 103.503 59 101.661 59H82.3392C80.4951 59 79 60.342 79 61.9992Z"
                  fill="#FEFCED"
                />
                <path
                  d="M44 61.9992C44 60.3404 42.5029 59 40.6601 59H21.3399C19.4953 59 18 60.342 18 61.9992C18 63.6565 19.4971 65 21.3399 65H40.6601C42.5046 65 44 63.658 44 61.9992Z"
                  fill="#FEFCED"
                />
                <path
                  d="M66.2398 61.476L81.9324 46.6198C83.2401 45.3811 83.2941 43.3233 82.0556 42.0172C80.8154 40.7094 78.7517 40.6571 77.4457 41.8925L61.5 56.9897L45.5543 41.8925C44.2466 40.6571 42.1863 40.7094 40.9444 42.0172C39.7059 43.3233 39.7616 45.3811 41.0676 46.6198L56.7602 61.476L41.017 76.3811C39.7109 77.6198 39.6553 79.6776 40.8938 80.9837C41.5367 81.6612 42.3973 82 43.2595 82C44.0661 82 44.8709 81.7051 45.502 81.1085L61.5 65.9624L77.498 81.1085C78.1291 81.7051 78.9356 82 79.7405 82C80.6044 82 81.465 81.6596 82.1062 80.9837C83.3447 79.6776 83.2891 77.6198 81.983 76.3811L66.2398 61.476Z"
                  fill="#FEFCED"
                />
              </svg>
              <span className="text-white text-sm">Menu</span>
            </button>
          </div>
        </div>
      </header>
      <If condition={open}>
        <div className="fixed w-full h-full left-0 top-0 z-10 bg-dark-blue mix-blend-multiply do-animate-up delay-700"></div>
        <nav
          className="dropdown-nav fixed top-0 left-0 right-0 z-40 bg-transparent flex flex-col gap-8  mt-[150px] overflow-auto w-full h-full pb-50 main-nav do-animate-up delay-700"
          data-lenis-prevent
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 px-gutter h-max relative z-[1]">
            <For
              each={communities}
              render={(comm) => {
                const isExternal = comm.isExternal;
                const externalLink = comm.externalLink;
                const internalLink = `/communities/${comm.slug}`;
                const target = isExternal ? "_blank" : "_self";
                const link = isExternal ? externalLink : internalLink;

                return (
                  <div key={comm.title} className="stack gap-6 w-full">
                    <Link href={link} target={target}>
                      <Image
                        src={comm.cardImage?.url || comm.featuredImage?.url}
                        alt={comm.title}
                        className="aspect-[622/400]  w-full"
                        imgClassName="rounded aspect-[622/400] w-full"
                        maxWidth="600"
                      />
                    </Link>
                    <div>
                      <p className="text-white text-sm">
                        {comm.city}, {comm.state}
                      </p>
                      <Link
                        href={link}
                        target={target}
                        className="text-white hover:text-orange transition-all duration-300"
                      >
                        {comm.title}
                      </Link>
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <Button link="/communities" className="mx-auto relative z-[1]">
            View All Communities
          </Button>
        </nav>
      </If>
      <If condition={mobileOpen}>
        <div className="fixed w-full h-full left-0 top-0 z-10 bg-teal do-animate-up delay-700"></div>
        <nav
          className="dropdown-nav fixed top-0 left-0 right-0 z-40 bg-transparent flex flex-col gap-8  mt-[150px] overflow-auto w-full h-full pb-50 main-nav do-animate-up delay-700"
          data-lenis-prevent
        >
          <div className="flex flex-col gap-12 px-gutter h-max relative z-[1]">
            <ul className="flex flex-col gap-6 items-left mobile-links">
              <For
                each={links}
                render={(link) => {
                  return <MobileLinkItem link={link} />;
                }}
              />
            </ul>
            {/* <For
              each={communities}
              render={(comm) => {
                return (
                  <div key={comm.title} className="stack gap-6">
                    <Link href={`/communities/${comm.slug}`}>
                      <Image
                        src={comm.cardImage?.url}
                        alt={comm.title}
                        className="aspect-[622/400]"
                        imgClassName="rounded aspect-[622/400]"
                        maxWidth="600"
                      />
                    </Link>
                    <div>
                      <p className="text-white text-sm">
                        {comm.city}, {comm.state}
                      </p>
                      <Link
                        href={`/communities/${comm.slug}`}
                        className="text-white hover:text-orange transition-all duration-300"
                      >
                        {comm.title}
                      </Link>
                    </div>
                  </div>
                );
              }}
            /> */}
          </div>
          {/* <Button link="/communities" className="mx-auto relative z-[1]">
            View All Communities
          </Button> */}
        </nav>
      </If>
    </>
  );
}
