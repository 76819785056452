import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__reac_ouz3f64nog3seua34hzsxlkzv4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__reac_ouz3f64nog3seua34hzsxlkzv4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__reac_ouz3f64nog3seua34hzsxlkzv4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/SmoothScrolling.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.scss");
