"use client";

import If from "@/components/If";
import Link from "next/link";

export default function Button({
  children,
  type = "button" || "submit" || "reset",
  link = "",
  className = "",
  showIcon = true,
  color = "pink",
  onClick = () => {},
  disabled = false,
  ...props
}) {
  const colors = {
    pink: "bg-pink text-black",
    red: "bg-red text-white",
    blue: "bg-blue text-white",
    yellow: "bg-yellow text-black",
    green: "bg-green text-white",
    white: "bg-white text-black",
    black: "bg-black text-white",
  };
  const borderColors = {
    pink: "border-black",
    red: "border-black",
    blue: "border-black",
    yellow: "border-black",
    green: "border-green",
    white: "border-white",
    black: "border-black",
  };
  const arrowColors = {
    pink: "black",
    red: "white",
    blue: "white",
    yellow: "black",
    green: "white",
    white: "black",
    black: "white",
  };
  return (
    <>
      <If condition={link}>
        <Link
          href={link}
          className={`px-8 min-w-max max-w-full border button ${
            colors[color || "pink"]
          } border-color text-border-color  flex items-center justify-between gap-4  leading-tight rounded-button ${className}`}
          {...props}
        >
          <span className="flex items-center justify-between gap-4 z-[1] w-full">
            {children}
            <If condition={showIcon}>
              <svg
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <g clipPath="url(#clip0_1_120)">
                  <path
                    d="M23.8883 10.476L13.1274 0L11.6146 1.47277L19.8163 9.4574H0V11.5405H19.7885L11.5868 19.5272L13.0996 21L23.8605 10.5219L23.8519 10.5135L23.8883 10.476Z"
                    fill="#E89211"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_120">
                    <rect width="23.8883" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </If>
          </span>
        </Link>
      </If>
      <If condition={!link}>
        <button
          type={type}
          disabled={disabled}
          onClick={onClick}
          className={`px-8 min-w-max max-w-full border button ${
            colors[color || "pink"]
          } border-color text-border-color flex items-center justify-between gap-4 leading-tight rounded-button ${className}`}
          {...props}
        >
          <span className="flex items-center justify-between gap-4 z-[1]">
            {children}
            <If condition={showIcon}>
              <svg
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <g clipPath="url(#clip0_1_120)">
                  <path
                    d="M23.8883 10.476L13.1274 0L11.6146 1.47277L19.8163 9.4574H0V11.5405H19.7885L11.5868 19.5272L13.0996 21L23.8605 10.5219L23.8519 10.5135L23.8883 10.476Z"
                    fill="#E89211"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_120">
                    <rect width="23.8883" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </If>
          </span>
        </button>
      </If>
    </>
  );
}
